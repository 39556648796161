import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  SocialMediaListeningKeywords,
  SocialMediaListeningManageCreatorsView,
  WordLimitedInputArea,
  useBrandDataForSocialListeningOnboarding,
  useBrandSearchTermsForSocialListeningOnboarding,
  useTopicDataForSocialListeningOnboarding,
} from 'features/socialMediaListening';
import { ListeningTopicType, Platform, PlotFeature } from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

type Props = {
  currentBrandId: string;
  currentTopicId?: string;
  readOnly?: boolean;
};

export const SocialListeningSettingsTopicsView = ({
  currentBrandId,
  currentTopicId,
  readOnly,
}: Props) => {
  const {
    dialog: deleteTopicConfirmation,
    onOpen: openDeleteTopicConfirmationDialog,
  } = useConfirmationDialog();

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);

  const {
    brand,
    refetchBrand,
    onboardingActions: { handleBrandDescriptionUpdate },
  } = useBrandDataForSocialListeningOnboarding({ brandId: currentBrandId });
  const {
    topicActions: {
      handleAddBrandMentionedTopic,
      handleAddEngagementTopic,
      handleAddCreatorTrackingTopic,
      handleDeleteTopic,
      handleUpdateTopicActiveStatus,
      updatingTopicStatus,
    },
  } = useTopicDataForSocialListeningOnboarding({ topics: brand?.topics || [] });
  const {
    searchTermActions: { handleCreateSearchTerms },
  } = useBrandSearchTermsForSocialListeningOnboarding();

  const tiktokHandle = brand?.creators.find(
    (c) => c.platform === Platform.Tiktok,
  )?.handle;
  const instagramHandle = brand?.creators.find(
    (c) => c.platform === Platform.Instagram,
  )?.handle;

  const topics = brand?.topics.length
    ? [
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.BrandMention,
        ),
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.TopicEngagement,
        ),
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.CreatorTracking,
        ),
      ]
    : [];

  const [currentTopic, setCurrentTopic] = useState(topics[0]);
  const [updatedTopicName, setUpdatedTopicName] = useState('');

  const [aboutFieldValue, setAboutFieldValue] = useState('');
  const [prioritizationInfo, setPrioritizationInfo] = useState('');
  const [communicationStrategy, setCommunicationStrategy] = useState('');

  useEffect(() => {
    if (!currentTopic) {
      setCurrentTopic(topics[0]);
    } else {
      const topic = topics.find((topic) => topic.id === currentTopic.id);
      if (topic) {
        setCurrentTopic(topic);
      }
    }
  }, [brand]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentTopicId) {
      const topic = topics.find((topic) => topic.id === currentTopicId);
      if (topic) {
        setCurrentTopic(topic);
      }
    }
  }, [currentTopicId, currentBrandId]); // eslint-disable-line

  const aboutValue =
    currentTopic?.type === ListeningTopicType.BrandMention
      ? brand?.description
      : currentTopic?.type === ListeningTopicType.TopicEngagement
      ? currentTopic?.topicDescription
      : currentTopic?.type === ListeningTopicType.CreatorTracking
      ? currentTopic?.topicDescription
      : '';

  useEffect(() => {
    setAboutFieldValue(aboutValue || '');
    if (prioritizationInfo !== currentTopic?.prioritizationInfo) {
      setPrioritizationInfo(currentTopic?.prioritizationInfo || '');
    }
    if (communicationStrategy !== currentTopic?.communicationStrategy) {
      setCommunicationStrategy(currentTopic?.communicationStrategy || '');
    }

    setUpdatedTopicName(currentTopic?.name || '');
  }, [currentTopic, brand]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateAboutValue = async (value: string) => {
    if (currentTopic.type === ListeningTopicType.BrandMention) {
      await handleBrandDescriptionUpdate(value);
    } else if (currentTopic.type === ListeningTopicType.TopicEngagement) {
      if (!brand?.id) {
        return;
      }
      await handleAddEngagementTopic(
        {
          topicDescription: value,
          communicationStrategy:
            currentTopic.communicationStrategy || undefined,
          prioritizationInfo: currentTopic.prioritizationInfo || undefined,
        },
        brand.id,
        currentTopic.id,
      );
    } else if (currentTopic.type === ListeningTopicType.CreatorTracking) {
      if (!brand?.id) {
        return;
      }
      await handleAddCreatorTrackingTopic(
        {
          topicDescription: value,
          communicationStrategy:
            currentTopic.communicationStrategy || undefined,
          prioritizationInfo: currentTopic.prioritizationInfo || undefined,
        },
        brand.id,
        currentTopic.id,
      );
    }
  };

  const handleUpdateCommunicationStrategy = async (value: string) => {
    if (!brand?.id) {
      return;
    }

    if (currentTopic.type === ListeningTopicType.BrandMention) {
      handleAddBrandMentionedTopic(brand.id, brand?.name, {
        communicationStrategy: value,
        prioritizationInfo: currentTopic.prioritizationInfo || '',
      });
    } else if (currentTopic.type === ListeningTopicType.TopicEngagement) {
      handleAddEngagementTopic(
        {
          topicDescription: currentTopic.topicDescription || '',
          communicationStrategy: value,
          prioritizationInfo: currentTopic.prioritizationInfo || '',
        },
        brand.id,
        currentTopic.id,
      );
    } else if (currentTopic.type === ListeningTopicType.CreatorTracking) {
      handleAddCreatorTrackingTopic(
        {
          topicDescription: currentTopic.topicDescription || '',
          communicationStrategy: value,
          prioritizationInfo: currentTopic.prioritizationInfo || '',
        },
        brand.id,
        currentTopic.id,
      );
    }
  };

  const handleUpdatePrioritizationInfo = async (value: string) => {
    if (!brand?.id) {
      return;
    }

    if (currentTopic.type === ListeningTopicType.BrandMention) {
      handleAddBrandMentionedTopic(brand.id, brand?.name, {
        communicationStrategy: currentTopic.communicationStrategy || '',
        prioritizationInfo: value,
      });
    } else if (currentTopic.type === ListeningTopicType.TopicEngagement) {
      handleAddEngagementTopic(
        {
          topicDescription: currentTopic.topicDescription || '',
          communicationStrategy: currentTopic.communicationStrategy || '',
          prioritizationInfo: value,
        },
        brand.id,
        currentTopic.id,
      );
    } else if (currentTopic.type === ListeningTopicType.CreatorTracking) {
      handleAddCreatorTrackingTopic(
        {
          topicDescription: currentTopic.topicDescription || '',
          communicationStrategy: currentTopic.communicationStrategy || '',
          prioritizationInfo: value,
        },
        brand.id,
        currentTopic.id,
      );
    }
  };

  const onDeleteTopic = async () => {
    openDeleteTopicConfirmationDialog({
      onConfirm: async () => {
        await handleDeleteTopic(currentTopic.id);
        await refetchBrand();
        setCurrentTopic(topics[0]);
      },
      title: 'Are you sure?',
      subtitle: 'Deleting this topic is a permanent action?',
      confirmText: 'Delete topic',
      cancelText: 'Cancel',
    });
  };

  const onUpdateTopicActiveStatus = async (status: boolean) => {
    await handleUpdateTopicActiveStatus(status, currentTopic.id);
  };

  const onTopicNameUpdated = async (name: string) => {
    if (
      currentTopic.type === ListeningTopicType.TopicEngagement ||
      currentTopic.type === ListeningTopicType.CreatorTracking
    ) {
      handleAddEngagementTopic(
        {
          topicName: name,
        },
        brand?.id || '',
        currentTopic.id,
      );
    } else if (currentTopic.type === ListeningTopicType.BrandMention) {
      handleAddBrandMentionedTopic(brand?.id || '', brand?.name || '', {
        name,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Typography variant="headline-md" fontSize={theme.spacing(5)}>
        Topics
      </Typography>

      <Box display="flex" justifyContent="space-between" flex={1}>
        <ContextMenu
          renderButton={() => (
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              sx={{
                cursor: 'pointer',
                border: `2px solid ${theme.colors?.utility[400]}`,
                backgroundColor: theme.colors?.utility[300],
                borderRadius: theme.spacing(2),
                padding: theme.spacing(2, 4),
              }}
            >
              <Typography
                variant="body-xl"
                color={theme.colors?.primary.black}
                sx={{
                  fontWeight: 600,
                }}
                onClick={() => {
                  setUpdatedTopicName(currentTopic?.name || '');
                }}
              >
                {currentTopic?.name}
              </Typography>
              <IconLinearArrowDown size={16} />
            </Box>
          )}
          options={topics.map((topic) => ({
            renderOption: () => (
              <Box
                sx={{
                  flex: 1,
                }}
                onClick={() => {
                  setCurrentTopic(topic);
                }}
              >
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[800]}
                  fontWeight={600}
                >
                  {topic.name}
                </Typography>
              </Box>
            ),
            onClick: () => {},
          }))}
        />
        {/* <Link to={PlotRoutes.socialListeningOnboarding('topic')}> */}
        {!readOnly && (
          <ContextMenu
            renderButton={() => (
              <Button
                variant="outlined"
                endIcon={<IconBoldAddCircle size={16} />}
                sx={{
                  '&, &:hover': {
                    borderRadius: 10,
                    borderWidth: 2,
                    borderColor: theme.colors?.primary.black,
                    backgroundColor: theme.colors?.primary.black,
                    color: theme.colors?.primary.parchment,
                  },
                }}
              >
                <Typography variant="headline-sm">New topic</Typography>
              </Button>
            )}
            options={[
              {
                renderOption: () => (
                  <Link
                    to={PlotRoutes.socialListeningOnboarding(
                      'topic',
                      currentBrandId,
                    )}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        padding: theme.spacing(1),
                        borderRadius: theme.spacing(3),
                        '&:hover': {
                          backgroundColor: theme.colors?.utility[275],
                        },
                      }}
                    >
                      <Typography variant="body-lg" fontWeight={500}>
                        Topic Insights
                      </Typography>
                      <Typography
                        variant="body-sm"
                        fontWeight={500}
                        color={theme.colors?.utility[900]}
                      >
                        Track conversations across customized topics
                      </Typography>
                    </Box>
                  </Link>
                ),
              },
              ...(creatorTrackingEnabled
                ? [
                    {
                      renderOption: () => (
                        <Link
                          to={PlotRoutes.socialListeningOnboarding(
                            'creator',
                            currentBrandId,
                          )}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                              padding: theme.spacing(1),
                              borderRadius: theme.spacing(3),
                              '&:hover': {
                                backgroundColor: theme.colors?.utility[275],
                              },
                            }}
                          >
                            <Typography variant="body-lg" fontWeight={500}>
                              Creator Focus
                            </Typography>
                            <Typography
                              variant="body-sm"
                              fontWeight={500}
                              color={theme.colors?.utility[900]}
                            >
                              Track specific creators discussing your key topics
                            </Typography>
                          </Box>
                        </Link>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        )}

        {/* </Link> */}
      </Box>

      <TextField
        disabled={readOnly}
        sx={{
          width: theme.spacing(100),
          mt: 4,
          '.MuiOutlinedInput-root': {
            py: `${theme.spacing(4)} !important`,
            px: `${theme.spacing(4)} !important`,
            bgcolor: 'rgba(255, 255, 255, 0.10)',
            borderRadius: 3,
            border: `2px solid ${theme.colors?.utility[300]}`,
            background: theme.colors?.utility[200],

            input: {
              p: '0 !important',
              ...theme.typography['body-lg'],
            },

            '.MuiOutlinedInput-notchedOutline': {
              display: 'none !important',
            },
          },
        }}
        value={updatedTopicName}
        onChange={(e) => {
          setUpdatedTopicName(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onTopicNameUpdated(updatedTopicName);
          }
        }}
        onBlur={() => {
          onTopicNameUpdated(updatedTopicName);
        }}
      />

      <Box display="flex" gap={3}>
        <Box display="flex" flexDirection="column" gap={3} flex={3}>
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <Typography variant="headline-md">
              About{' '}
              {currentTopic?.type === ListeningTopicType.BrandMention
                ? 'the brand'
                : currentTopic?.type === ListeningTopicType.TopicEngagement ||
                  currentTopic?.type === ListeningTopicType.CreatorTracking
                ? 'the topic'
                : ''}
            </Typography>
            <Typography variant="body-xl" color={theme.colors?.utility[800]}>
              Fill us in about your company. For example, what do you sell? Who
              is your target audience?
            </Typography>

            <WordLimitedInputArea
              disabled={readOnly}
              autoFocus={false}
              value={aboutFieldValue}
              onChange={(e) => {
                setAboutFieldValue(e.target.value);
              }}
              onBlur={(e) => {}}
              maxWords={100}
              componentProps={{
                wordUsage: {
                  sx: {
                    position: 'absolute',
                    left: theme.spacing(4),
                    bottom: theme.spacing(4),
                    color: theme.colors?.utility[700],
                  },
                },
              }}
              multiline
              minRows={7}
              maxRows={12}
              sx={{
                mt: 4,
                '.MuiOutlinedInput-root': {
                  py: `${theme.spacing(4)} !important`,
                  px: `${theme.spacing(4)} !important`,
                  bgcolor: 'rgba(255, 255, 255, 0.10)',
                  borderRadius: 3,
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  background: theme.colors?.utility[200],

                  input: {
                    p: '0 !important',
                    ...theme.typography['body-xl'],
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            >
              {aboutValue !== aboutFieldValue && (
                <Button
                  fullWidth={false}
                  variant="primary"
                  onClick={() => {
                    updateAboutValue(aboutFieldValue);
                  }}
                  sx={{
                    position: 'absolute',
                    right: theme.spacing(4),
                    bottom: theme.spacing(6),
                    mt: 4,
                    p: theme.spacing(2, 4),
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.primary.black,
                    border: 'none',
                    '&:hover': {
                      backgroundColor: theme.colors?.primary.black,
                    },
                  }}
                >
                  Save changes
                </Button>
              )}
            </WordLimitedInputArea>
          </Box>
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <Typography variant="headline-md">
              Prioritize incoming posts{' '}
            </Typography>
            <Typography variant="body-xl" color={theme.colors?.utility[800]}>
              Fill us in about your company. For example, what do you sell? Who
              is your target audience?
            </Typography>

            <WordLimitedInputArea
              disabled={readOnly}
              autoFocus={false}
              value={prioritizationInfo}
              onChange={(e) => {
                setPrioritizationInfo(e.target.value);
              }}
              maxWords={100}
              componentProps={{
                wordUsage: {
                  sx: {
                    position: 'absolute',
                    left: theme.spacing(4),
                    bottom: theme.spacing(4),
                    color: theme.colors?.utility[700],
                  },
                },
              }}
              multiline
              minRows={7}
              maxRows={12}
              sx={{
                mt: 4,
                '.MuiOutlinedInput-root': {
                  py: `${theme.spacing(4)} !important`,
                  px: `${theme.spacing(4)} !important`,
                  bgcolor: 'rgba(255, 255, 255, 0.10)',
                  borderRadius: 3,
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  background: theme.colors?.utility[200],

                  input: {
                    p: '0 !important',
                    ...theme.typography['body-xl'],
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            >
              {prioritizationInfo !== currentTopic?.prioritizationInfo && (
                <Button
                  fullWidth={false}
                  variant="primary"
                  onClick={() => {
                    handleUpdatePrioritizationInfo(prioritizationInfo);
                  }}
                  sx={{
                    position: 'absolute',
                    right: theme.spacing(4),
                    bottom: theme.spacing(6),
                    mt: 4,
                    p: theme.spacing(2, 4),
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.primary.black,
                    border: 'none',
                    '&:hover': {
                      backgroundColor: theme.colors?.primary.black,
                    },
                  }}
                >
                  Save changes
                </Button>
              )}
            </WordLimitedInputArea>
          </Box>
          <Box display="flex" flexDirection="column" mt={2} gap={3}>
            <Typography variant="headline-md">
              Communication strategy
            </Typography>
            <Typography variant="body-xl" color={theme.colors?.utility[800]}>
              Fill us in about your company. For example, what do you sell? Who
              is your target audience?
            </Typography>

            <WordLimitedInputArea
              disabled={readOnly}
              autoFocus={false}
              value={communicationStrategy}
              onChange={(e) => {
                setCommunicationStrategy(e.target.value);
              }}
              maxWords={200}
              componentProps={{
                wordUsage: {
                  sx: {
                    position: 'absolute',
                    left: theme.spacing(4),
                    bottom: theme.spacing(4),
                    color: theme.colors?.utility[700],
                  },
                },
              }}
              multiline
              minRows={7}
              maxRows={12}
              sx={{
                mt: 4,
                '.MuiOutlinedInput-root': {
                  py: `${theme.spacing(4)} !important`,
                  px: `${theme.spacing(4)} !important`,
                  bgcolor: 'rgba(255, 255, 255, 0.10)',
                  borderRadius: 3,
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  background: theme.colors?.utility[200],

                  input: {
                    p: '0 !important',
                    ...theme.typography['body-xl'],
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            >
              {communicationStrategy !==
                currentTopic?.communicationStrategy && (
                <Button
                  fullWidth={false}
                  variant="primary"
                  onClick={() => {
                    handleUpdateCommunicationStrategy(communicationStrategy);
                  }}
                  sx={{
                    position: 'absolute',
                    right: theme.spacing(4),
                    bottom: theme.spacing(6),
                    mt: 4,
                    p: theme.spacing(2, 4),
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.primary.black,
                    border: 'none',
                    '&:hover': {
                      backgroundColor: theme.colors?.primary.black,
                    },
                  }}
                >
                  Save changes
                </Button>
              )}
            </WordLimitedInputArea>
          </Box>
        </Box>

        {currentTopic &&
          currentTopic.type !== ListeningTopicType.CreatorTracking && (
            <Box display="flex" flexDirection="column" gap={3} flex={2}>
              <Typography variant="headline-md">Keywords</Typography>
              <Typography variant="body-xl" color={theme.colors?.utility[800]}>
                Fill us in about your company. For example, what do you sell?
                Who is your target audience?
              </Typography>
              <Box
                sx={{
                  backgroundColor: theme.colors?.utility[200],
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  p: 4,
                  borderRadius: 3,
                }}
              >
                <SocialMediaListeningKeywords
                  readOnly={readOnly}
                  keywords={(currentTopic?.searchTerms || []).map(
                    (term) => term.term,
                  )}
                  updateKeywords={async (updatedKeywords) => {
                    await handleCreateSearchTerms(
                      currentTopic.id,
                      updatedKeywords,
                      currentTopic.allowToUpdateSearchTerm,
                    );
                    refetchBrand();
                  }}
                  componentProps={{
                    createKeyword: {
                      position: 'pre',
                      sx: {
                        mt: 0,
                        fontWeight: 500,
                        backgroundColor: theme.colors?.utility[300],
                        color: theme.colors?.utility[600],
                        '& .MuiInputBase-root': {
                          color: `${theme.colors?.primary.black} !important`,
                        },
                        '& .keyword-add': {
                          color: theme.colors?.utility[600],
                        },
                        '& .keyword-label': {
                          fontSize: theme.spacing(3.5),
                        },
                      },
                    },
                    keyword: {
                      sx: {
                        backgroundColor: theme.colors?.utility[300],
                        color: theme.colors?.primary.black,
                        fontWeight: 400,
                        '& .keyword-delete': {
                          color: theme.colors?.utility[600],
                        },
                        '& .keyword-label': {
                          fontSize: theme.spacing(3.5),
                          color: theme.colors?.primary.black,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          )}

        {currentTopic &&
          currentTopic.type === ListeningTopicType.CreatorTracking && (
            <Box display="flex" flexDirection="column" gap={3} flex={2}>
              <Typography variant="headline-md">Creator list</Typography>
              <Typography variant="body-xl" color={theme.colors?.utility[800]}>
                Who are the creators you want to track?
              </Typography>
              <Box
                sx={{
                  backgroundColor: theme.colors?.utility[200],
                  border: `2px solid ${theme.colors?.utility[300]}`,
                  p: 4,
                  borderRadius: 3,
                }}
              >
                <SocialMediaListeningManageCreatorsView
                  topicId={currentTopic.id}
                  tiktokHandle={tiktokHandle}
                  instagramHandle={instagramHandle}
                />
              </Box>
            </Box>
          )}
      </Box>

      <Box display="flex" gap={3} mt={4}>
        <Button
          disabled={readOnly}
          variant="primary"
          sx={{
            '&, &:hover': {
              p: theme.spacing(2, 4),
              width: 'fit-content',
              color: theme.colors?.primary.black,
              backgroundColor: theme.colors?.primary.white,
              borderRadius: 2,
              border: `1px solid ${theme.colors?.primary.black}`,
            },
          }}
          startIcon={
            currentTopic?.active ? (
              <IconBoldPauseCircle size={16} />
            ) : (
              <IconBoldPlayCircle size={16} />
            )
          }
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            onUpdateTopicActiveStatus(!currentTopic?.active)
          }
        >
          <Typography variant="headline-sm">
            {currentTopic?.active ? 'Pause topic' : 'Resume Topic'}
          </Typography>
          {updatingTopicStatus && (
            <CircularProgress
              size={16}
              sx={{ color: theme.colors?.primary.maroon, marginLeft: 2 }}
            />
          )}
        </Button>
        {(currentTopic?.type === ListeningTopicType.TopicEngagement ||
          currentTopic?.type === ListeningTopicType.CreatorTracking) &&
          !readOnly && (
            <Button
              variant="primary"
              sx={{
                '&, &:hover': {
                  p: theme.spacing(2, 4),
                  width: 'fit-content',
                  color: theme.colors?.primary.parchment,
                  backgroundColor: theme.colors?.utility['pink-3'],
                  borderRadius: 2,
                  border: 'none',
                },
              }}
              startIcon={<IconBoldTrash size={16} />}
              onClick={onDeleteTopic}
            >
              <Typography variant="headline-sm">Delete topic</Typography>
            </Button>
          )}
      </Box>
      {deleteTopicConfirmation}
    </Box>
  );
};
