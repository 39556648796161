import { gql } from '@apollo/client';
import { Box, Radio, Tooltip, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { useUserContext } from 'contexts/users/User.context';
import {
  ExternalOrganizationRole,
  InternalOrganizationRole,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment UserFragmentAddUsersToOrgViewMemberUserRoleSelection on UserProfileModel {
    id
    firstName
    lastName
    email
    avatarUrl
  }
`;

type Props = {
  userRoleData: {
    [email: string]: InternalOrganizationRole | ExternalOrganizationRole;
  };
  onRoleChange: (
    userEmail: string,
    role: InternalOrganizationRole | ExternalOrganizationRole,
  ) => void;
  guestOptions?: {
    defaultSelectedRoleForExternalUser: InternalOrganizationRole;
  };
};

export const MemberUserRoleSelection = ({
  onRoleChange,
  userRoleData,
  guestOptions,
}: Props) => {
  const {
    defaultSelectedRoleForExternalUser = InternalOrganizationRole.SocialListeningUser,
  } = guestOptions || {};

  const isUserRoleChecked = (
    role: InternalOrganizationRole | ExternalOrganizationRole,
  ) => {
    if (
      role === ExternalOrganizationRole.Collaborator &&
      defaultSelectedRoleForExternalUser === InternalOrganizationRole.User
    ) {
      return true;
    }
    return role === InternalOrganizationRole.User;
  };

  const isSocialListeningRoleChecked = (
    role: InternalOrganizationRole | ExternalOrganizationRole,
  ) => {
    if (
      role === ExternalOrganizationRole.Collaborator &&
      defaultSelectedRoleForExternalUser ===
        InternalOrganizationRole.SocialListeningUser
    ) {
      return true;
    }

    return role === InternalOrganizationRole.SocialListeningUser;
  };
  const { orgBilling } = useUserContext();
  const paidMemberLimitReached = orgBilling
    ? orgBilling.organizationMemberUsage >=
      (orgBilling.organizationMemberLimit || 1)
    : false;

  return (
    <Box>
      <Box
        display="flex"
        gap={4}
        py={2}
        flex={1}
        sx={{ borderBottom: `1px solid ${theme.colors?.utility[500]}`, px: 3 }}
      >
        <Box display="flex" flex={1}>
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[1000]}
            fontWeight={600}
          >
            Users
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1} flex={1}>
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[1000]}
            fontWeight={600}
          >
            All Plot features
          </Typography>
          <Typography variant="body-md" color={theme.colors?.utility[700]}>
            Additional paid user
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1} flex={1}>
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[1000]}
            fontWeight={600}
          >
            Social Listening Only
          </Typography>
          <Typography variant="body-md" color={theme.colors?.utility[700]}>
            Included
          </Typography>
        </Box>
      </Box>
      {Object.keys(userRoleData).map((email) => (
        <Box
          display="flex"
          gap={4}
          py={4}
          sx={{
            width: '100%',
            borderBottom: `1px solid ${theme.colors?.utility[500]}`,
            px: 3,
          }}
        >
          <Box display="flex" flex={1} gap={2} overflow="hidden">
            <Tooltip title={email} placement="top">
              <Box display="flex" gap={2} alignItems="center">
                <Avatar
                  size={20}
                  user={{
                    id: '',
                    email,
                    firstName: '',
                    lastName: '',
                  }}
                />
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[1000]}
                  fontWeight={600}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                  }}
                >
                  {email}
                  {userRoleData[email] ===
                    ExternalOrganizationRole.Collaborator && (
                    <Typography
                      display="block"
                      variant="body-md"
                      color={theme.colors?.utility[700]}
                    >
                      Guest
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            flex={1}
            alignItems="flex-start"
          >
            <Radio
              disabled={
                paidMemberLimitReached ||
                userRoleData[email] === ExternalOrganizationRole.Collaborator
              }
              checked={isUserRoleChecked(userRoleData[email])}
              onChange={() => {
                onRoleChange(email, InternalOrganizationRole.User);
              }}
              disableRipple
              sx={{
                p: 0,
                '&.Mui-disabled': {
                  color: `${theme.colors?.utility[500]} !important`,
                },
                '&.MuiRadio-root': {
                  color: theme.colors?.primary.black,
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            flex={1}
            alignItems="flex-start"
          >
            <Radio
              disabled={
                userRoleData[email] === ExternalOrganizationRole.Collaborator
              }
              checked={isSocialListeningRoleChecked(userRoleData[email])}
              onChange={() => {
                onRoleChange(
                  email,
                  InternalOrganizationRole.SocialListeningUser,
                );
              }}
              disableRipple
              sx={{
                p: 0,
                '&.MuiRadio-root': {
                  color: theme.colors?.primary.black,
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-disabled': {
                  color: theme.colors?.utility[500],
                },
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
