import { Avatar, Box, Typography } from '@mui/material';
import { IconLinearMicrophone } from 'components/icons/components/linear/IconLinearMicrophone';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { NotificationsSection } from 'features/socialListeningSettings/views/notifications';
import { PlotFeature } from 'graphql/generated';
import React from 'react';
import { theme } from 'styles/theme';

type Props = {};

export const Notifications = (props: Props) => {
  const { user } = useUserContext();
  const { isFeatureEnabled } = useFeatureFlagContext();
  const userSlBrands = user?.socialListeningBrands || [];

  return (
    <Box>
      <Typography variant="headline-lg" fontSize={theme.spacing(7)}>
        Notifications
      </Typography>

      <Box
        height="1"
        my={4}
        sx={{
          borderBottom: `1px solid ${theme.colors?.utility[400]}`,
        }}
      />

      <Typography
        variant="headline-md"
        display="flex"
        alignItems="center"
        gap={2}
        mb={6}
        ml={3}
      >
        <IconLinearMicrophone size={20} />
        Social Listening
      </Typography>

      {userSlBrands.map((slBrand) => (
        <Box sx={{}}>
          <Box display="flex" alignItems="center" gap={2} pl={3}>
            <Avatar
              sx={{
                width: theme.spacing(6),
                height: theme.spacing(6),
              }}
              src={
                slBrand.creators?.[0]?.profilePictureUrl ||
                slBrand.creators?.[1]?.profilePictureUrl ||
                ''
              }
            />
            <Typography
              variant="headline-sm"
              color={theme.colors?.primary.black}
            >
              {slBrand.name}
            </Typography>
          </Box>
          {isFeatureEnabled(PlotFeature.SocialListeningNotifications) && (
            <NotificationsSection
              currentBrandId={slBrand.id}
              componentProps={{
                hideLabels: true,
              }}
            />
          )}

          <Box
            height="1"
            my={8}
            sx={{
              borderBottom: `1px solid ${theme.colors?.utility[400]}`,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
