import { Box, Avatar as MuiAvatar, SxProps, Typography } from '@mui/material';
import { IconOutlineClock } from 'components/icons/components/outline/IconOutlineClock';
import { UserFragmentAvatarGroupFragment } from 'graphql/generated';
import { forwardRef } from 'react';
import { theme } from 'styles/theme/theme';
import { getFullName } from 'utils/users';
import { Tooltip } from '../Tooltip';
import { HtmlTooltip, StyledUserEmail, StyledUserName } from './styles';
import { stringAvatar, stringToColor } from './utils/avatar';

interface Props {
  user: UserFragmentAvatarGroupFragment;
  size: number;
  avatarSx?: SxProps;
  helperText?: string;
  helperIcon?: () => React.ReactNode;
  helperIconSx?: SxProps;
  disable?: boolean;
  customTooltip?: (
    user: UserFragmentAvatarGroupFragment,
    children: React.ReactElement<any, any>,
  ) => React.ReactNode;
}

export const Avatar = forwardRef((props: Props, ref) => {
  const {
    user,
    size,
    avatarSx,
    helperText,
    helperIcon,
    helperIconSx,
    disable,
    customTooltip,
  } = props;

  const fullName = getFullName(user);

  const toolTipChildren = (
    <MuiAvatar
      // Font size should be relative to the container size
      sx={{
        width: size,
        height: size,
        fontSize: size / 1.6,
        opacity: user.isDisabled ? '0.5' : 1,
        border: 'none !important',
        ...avatarSx,
      }}
      alt={fullName}
      src={user.avatarUrl || ''}
    >
      <TextAvatar {...props} />
    </MuiAvatar>
  );

  return (
    <Box position="relative" ref={ref}>
      {disable ? (
        <MuiAvatar
          sx={{
            width: size,
            height: size,
            fontSize: size / 1.6,
            opacity: '0.2',
            border: 'none !important',
            ...avatarSx,
          }}
          alt={fullName}
          src={user.avatarUrl || ''}
        >
          <TextAvatar {...props} />
        </MuiAvatar>
      ) : (
        <>
          {!user.isDisabled && helperIcon && helperText && (
            <Tooltip title={helperText}>
              <MuiAvatar
                sx={{
                  width: size / 2,
                  height: size / 2,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  backgroundColor: theme.colors?.utility.yellow,
                  border: 'none !important',
                  zIndex: 10,
                  ...helperIconSx,
                }}
              >
                {helperIcon()}
              </MuiAvatar>
            </Tooltip>
          )}

          {customTooltip ? (
            customTooltip(user, toolTipChildren)
          ) : (
            <HtmlTooltip
              key={user.email}
              title={
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={2}
                  alignItems="center"
                >
                  <MuiAvatar
                    sx={{ width: 32, height: 32 }}
                    alt={fullName}
                    src={user.avatarUrl || ''}
                  >
                    <TextAvatar {...props} size={32} />
                  </MuiAvatar>
                  <Box display="flex" gap={1} flexDirection="column">
                    <StyledUserName variant="body-lg">
                      {fullName}
                    </StyledUserName>
                    <StyledUserEmail variant="body-xs">
                      {user.email}
                    </StyledUserEmail>
                  </Box>
                </Box>
              }
            >
              {toolTipChildren}
            </HtmlTooltip>
          )}
        </>
      )}
    </Box>
  );
});

const TextAvatar = ({ size, user, avatarSx }: Props) => {
  const fullName = getFullName(user);
  return (
    <Box
      sx={{
        backgroundColor: stringToColor(fullName || user.email),
        height: size,
        width: size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        borderRadius: '50%',
        ...avatarSx,
      }}
    >
      <Typography
        variant="subhead-xl"
        textTransform="uppercase"
        sx={{ fontSize: size / 1.6 }}
      >
        {stringAvatar(fullName || user.email)}
      </Typography>
    </Box>
  );
};
