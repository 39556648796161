import { Box } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { useSocialListeningPermissionsManageMembers } from 'features/socialListeningPermissions';
import {
  useSLBrandPermissions,
  useSocialListeningBrandSelector,
} from 'features/socialMediaListening';
import { PlotFeature } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SocialListeningSettingsCompetitorsView } from '../settingsCompetitors';
import { SocialListeningSettingsGeneral } from '../settingsGeneral';
import { SocialListeningSettingsTopicsView } from '../settingsTopics';
import { SocialListeningUsersView } from '../socialListeningUsers';

type TabType = 'general' | 'topics' | 'competitors' | 'users';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialListeningSettingsView = () => {
  const { user } = useUserContext();
  const [params] = useSearchParams();
  const defaultTab = params.get('tab');
  const currentTopicId = params.get('topicId');
  const brandId = params.get('brandId');

  const { currentBrandId, renderBrandSelector, handleBrandSelect } =
    useSocialListeningBrandSelector();
  const { hasFullAccess: userHasFullAccess } = useSLBrandPermissions({
    brandId: currentBrandId,
  });

  useEffect(() => {
    if (brandId) {
      handleBrandSelect(brandId);
    }
  }, [brandId]);

  const [tab, setTab] = useState((defaultTab || 'general') as TabType);
  const { isFeatureEnabled, loading } = useFeatureFlagContext();

  const userSlBrand = user?.socialListeningBrands?.find(
    (brand) => brand.id === currentBrandId,
  );
  const competitorAnalysisEnabled =
    isFeatureEnabled(PlotFeature.CompetitorAnalysis) ||
    (userSlBrand?.organizationId !== user?.organization.id &&
      userSlBrand?.competitorAnalysisEnabled);

  return (
    <Box
      sx={{
        width: '100%',
        fontSize: theme.spacing(4),

        '& .settings-container': {
          width: '100%',
          height: `calc(100% - ${theme.spacing(20)})`,
        },
        '& .MuiTabPanel-root': {
          height: '100%',
          overflowY: 'auto',
        },
        '& button.MuiTab-root': {
          pb: 0,
          fontSize: theme.spacing(4),
          my: 3,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box
        sx={{
          mt: 6,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {renderBrandSelector()}
        {/* <Box>{renderShareSocialListeningButton()}</Box> */}
      </Box>

      <Tabs
        className="settings-container"
        tab={tab}
        onChange={(val) => {
          setTab(val as TabType);
        }}
        tabs={[
          {
            key: 'general',
            label: <Box>General</Box>,
            content: (
              <SocialListeningSettingsGeneral
                key={currentBrandId}
                currentBrandId={currentBrandId || ''}
                readOnly={!userHasFullAccess}
              />
            ),
          },
          {
            key: 'users',
            label: <Box>Users</Box>,
            content: (
              <SocialListeningUsersView
                key={currentBrandId}
                brandId={currentBrandId}
              />
            ),
          },
          {
            key: 'topics',
            label: <Box>Topics</Box>,
            content: (
              <SocialListeningSettingsTopicsView
                key={currentBrandId}
                currentBrandId={currentBrandId || ''}
                currentTopicId={currentTopicId || ''}
                readOnly={!userHasFullAccess}
              />
            ),
          },
          ...(competitorAnalysisEnabled
            ? [
                {
                  key: 'competitors',
                  label: <Box>Competitors</Box>,
                  content: (
                    <SocialListeningSettingsCompetitorsView
                      key={currentBrandId}
                      currentBrandId={currentBrandId || ''}
                      readOnly={!userHasFullAccess}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Box>
  );
};
