import { gql } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Switch,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import {
  SocialMediaListeningKeywords,
  WordLimitedInputArea,
} from 'features/socialMediaListening/components';
import { CompetitorDetailType } from 'features/socialMediaListening/views/onboarding/SocialMediaListeningCompetitorsOnboardingView';
import {
  Platform,
  useTriggerFetchNewCreatorIntoDbForCompetitorDetailMutation,
  useGetAiGeneratedBrandDescriptionForCompetitorDetailLazyQuery,
  useGetAiGeneratedKeywordsForCompetitorDetailLazyQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { getCustomOperationContext } from 'utils/apollo';
import { SocialMediaListeningOnboardingCountrySelectionInput } from '../../../countrySelection';
import { RenderNextButton } from '../../../renderNextButton/RenderNextButton';
import { SocialMediaListeningOnboardingSocialHandleInput } from '../../../socialHandleForm/SocialMediaListeningOnboardingSocialHandleInput';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation TriggerFetchNewCreatorIntoDbForCompetitorDetail(
    $handle: String!
    $platform: String!
  ) {
    fetchNewCreatorIntoDb(handle: $handle, platform: $platform) {
      success
    }
  }
`;

type Props = {
  competitorDetail: CompetitorDetailType;
  updateCompetitorDetail: (competitor: CompetitorDetailType) => void;

  saving?: boolean;

  handleSubmit: () => void;
  componentProps?: {
    sx?: SxProps;
  };
};

export const CompetitorDetail = ({
  saving,
  competitorDetail,
  updateCompetitorDetail,
  handleSubmit,
  componentProps,
}: Props) => {
  const [
    fetchAIGeneratedBrandDescription,
    setFetchAIGeneratedBrandDescription,
  ] = useState(false);
  const [fetchAiKeywords, setFetchAiKeywords] = useState(false);

  const [fetchNewCreatorIntoDb] =
    useTriggerFetchNewCreatorIntoDbForCompetitorDetailMutation({
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });
  const [getAiGeneratedBrandDescription, { loading: loadingAiDescription }] =
    useGetAiGeneratedBrandDescriptionForCompetitorDetailLazyQuery();
  const [getAiGeneatedKeywords, { loading: loadingKeywords, data: keywords }] =
    useGetAiGeneratedKeywordsForCompetitorDetailLazyQuery();

  const { aboutBrand, name, instagramCreator, tiktokCreator } =
    competitorDetail;
  const isValidFormData = !!(
    (tiktokCreator || instagramCreator) &&
    name &&
    aboutBrand
  );

  useEffect(() => {
    if (competitorDetail.aboutBrand) {
      setFetchAIGeneratedBrandDescription(true);
    }

    if (competitorDetail.aiKeywords.length > 0) {
      setFetchAiKeywords(true);
    }
  }, [competitorDetail]);

  const fetchValueForAboutBrand = () => {
    setFetchAIGeneratedBrandDescription(true);
    getAiGeneratedBrandDescription({
      variables: {
        brandName: name,
      },
    }).then((data) => {
      updateCompetitorDetail({
        ...competitorDetail,
        aboutBrand: data.data?.aiGeneratedBrandDescription || '',
      });
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '60vh',
        display: 'flex',
        flexDirection: 'column',
        pb: theme.spacing(12),
        gap: 8,
        ...componentProps?.sx,
      }}
    >
      <Box display="flex" flexDirection="column" gap={2} width="100%">
        <Typography
          variant="headline-lg"
          fontSize={theme.spacing(5)}
          fontWeight={600}
        >
          Name
        </Typography>
        <Box display="flex" flexDirection="column" gap={3} width="100%">
          <TextField
            value={competitorDetail.name}
            onChange={(e) => {
              updateCompetitorDetail({
                ...competitorDetail,
                name: e.target.value,
              });
            }}
            autoFocus
            placeholder="Name"
            sx={{
              '.MuiOutlinedInput-root': {
                //   ...(hasError
                //     ? { border: `2px solid ${theme.colors?.utility['pink-1']}` }
                //     : {}),
                borderRadius: theme.spacing(4),
                py: `${theme.spacing(6)} !important`,
                px: `${theme.spacing(6)} !important`,
                bgcolor: 'rgba(255, 255, 255, 0.10)',
                color: theme.colors?.primary.parchment,

                input: {
                  ...theme.typography['headline-lg'],
                  p: '0 !important',
                },

                '.MuiOutlinedInput-notchedOutline': {
                  display: 'none !important',
                },
              },
            }}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={2} width="100%">
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          width="100%"
          position="relative"
        >
          <SocialMediaListeningOnboardingSocialHandleInput
            key={competitorDetail.tiktokCreator?.handle}
            autoFocus={false}
            platform={Platform.Tiktok}
            creator={competitorDetail.tiktokCreator}
            addCreator={async (creator) => {
              updateCompetitorDetail({
                ...competitorDetail,
                tiktokCreator: creator,
              });

              // silently create the creator in the db if it doesn't exist
              fetchNewCreatorIntoDb({
                variables: {
                  handle: creator.handle,
                  platform: creator.platform,
                },
              });
            }}
            removeCreator={async (creatorHandle: string) => {
              updateCompetitorDetail({
                ...competitorDetail,
                tiktokCreator: undefined,
              });
            }}
            componentProps={{
              edit: {
                sx: {
                  position: 'absolute',
                  right: theme.spacing(8),
                  mt: 4,
                  width: 'fit-content',
                  top: '50%',
                  transform: 'translateY(-50%)',
                },
              },
            }}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={2} width="100%">
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          width="100%"
          position="relative"
        >
          <SocialMediaListeningOnboardingSocialHandleInput
            key={competitorDetail.instagramCreator?.handle}
            autoFocus={false}
            platform={Platform.Instagram}
            creator={competitorDetail.instagramCreator}
            addCreator={async (creator) => {
              updateCompetitorDetail({
                ...competitorDetail,
                instagramCreator: creator,
              });

              // silently create the creator in the db if it doesn't exist
              fetchNewCreatorIntoDb({
                variables: {
                  handle: creator.handle,
                  platform: creator.platform,
                },
              });
            }}
            removeCreator={async (creatorId: string) => {
              updateCompetitorDetail({
                ...competitorDetail,
                instagramCreator: undefined,
              });
            }}
            componentProps={{
              edit: {
                sx: {
                  position: 'absolute',
                  right: theme.spacing(8),
                  mt: 4,
                  width: 'fit-content',
                  top: '50%',
                  transform: 'translateY(-50%)',
                },
              },
            }}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={2} width="100%">
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          width="100%"
          position="relative"
        >
          <Typography
            variant="headline-lg"
            fontSize={theme.spacing(5)}
            fontWeight={600}
          >
            Choose the country for sourcing video data.
          </Typography>
          <Typography variant="body-xl">
            For TikTok exclusively, choose a specific country to source video
            data, or leave the selection blank to target globally.
          </Typography>
          <SocialMediaListeningOnboardingCountrySelectionInput
            onCountriesUpdated={(countries) => {
              updateCompetitorDetail({
                ...competitorDetail,
                selectedCountries: countries.map((c) => c.code),
              });
            }}
            selectedCountries={[]}
            componentProps={{
              textField: {
                sx: {
                  '& .MuiOutlinedInput-root': {
                    width: '100%',
                    py: '0px !important',
                    px: '12px !important',

                    backgroundColor: 'rgba(255, 255, 255, 0.10)',
                    color: theme.colors?.primary.parchment,
                    borderRadius: 4,

                    input: {
                      ...typography['headline-lg'],
                      fontSize: theme.spacing(7),
                      p: `${theme.spacing(6)} !important`,
                      borderRadius: 4,
                    },

                    '.MuiOutlinedInput-notchedOutline': {
                      display: 'none !important',
                    },
                  },
                },
              },
            }}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        gap={4}
        flexDirection="column"
        color={theme.colors?.primary.parchment}
      >
        <Typography
          variant="headline-lg"
          fontSize={theme.spacing(5)}
          fontWeight={600}
        >
          Tell us about your brand. 🙇‍♀️
        </Typography>
        <Typography variant="body-xl" fontWeight={500}>
          Fill us in about your company. For example, what do you sell? Who is
          your target audience?
        </Typography>

        <Box position="relative">
          <WordLimitedInputArea
            key={competitorDetail.name}
            defaultValue={aboutBrand}
            disabled={!fetchAIGeneratedBrandDescription}
            maxWords={100}
            onChange={(e) => {
              updateCompetitorDetail({
                ...competitorDetail,
                aboutBrand: e.target.value,
              });
            }}
            componentProps={{
              wordUsage: {
                sx: {
                  color: theme.colors?.utility[500],
                },
              },
            }}
            placeholder={`${
              loadingAiDescription || !fetchAIGeneratedBrandDescription
                ? `

`
                : ''
            }Example: Plot is a software platform designed for social media teams and creators, offering a suite of tools to plan, source, and store content seamlessly across mobile and desktop. Our end-to-end solution empowers social media teams to efficiently manage their content and collaborate effortlessly across teams. 
 
Use cases range from saving ideas from different platforms like TikTok or Instagram, to storing photos and videos, or sourcing trends in Plot.`}
            multiline
            minRows={10}
            maxRows={16}
            sx={{
              mt: 4,
              '.MuiOutlinedInput-root': {
                borderRadius: theme.spacing(4),
                py: `${theme.spacing(8)} !important`,
                px: `${theme.spacing(8)} !important`,
                bgcolor: 'rgba(255, 255, 255, 0.10)',
                color: theme.colors?.primary.parchment,

                input: {
                  p: '0 !important',
                  ...theme.typography['body-xl'],
                },

                '.MuiOutlinedInput-notchedOutline': {
                  display: 'none !important',
                },
                '.Mui-disabled': {
                  backgroundColor: 'transparent',
                },
              },
            }}
          />
          {!fetchAIGeneratedBrandDescription && !loadingAiDescription && (
            <Button
              variant="primary"
              sx={{
                position: 'absolute',
                top: theme.spacing(10),
                left: theme.spacing(8),

                color: theme.colors?.utility.blueberry,
                backgroundColor: theme.colors?.primary.parchment,
                borderRadius: theme.spacing(2),
                border: 'none',
                '&:hover': {
                  backgroundColor: theme.colors?.primary.parchment,
                  color: theme.colors?.utility.blueberry,
                },
              }}
              endIcon={<IconCustomSparkles size={16} />}
              onClick={() => {
                fetchValueForAboutBrand();
              }}
            >
              Generate
            </Button>
          )}
          {fetchAIGeneratedBrandDescription && loadingAiDescription && (
            <Typography
              variant="body-xl"
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                position: 'absolute',
                top: theme.spacing(10),
                left: theme.spacing(8),

                color: theme.colors?.utility[500],
              }}
            >
              <CircularProgress
                size={16}
                sx={{
                  color: theme.colors?.utility[500],
                }}
              />
              Loading...
            </Typography>
          )}
        </Box>

        <Box>
          <Box my={4}>
            <Typography
              variant="headline-lg"
              fontSize={theme.spacing(5)}
              fontWeight={600}
            >
              Keywords
            </Typography>
          </Box>
          <Box
            position="relative"
            sx={{
              backgroundColor: 'rgba(250, 243, 236, 0.15)',
              borderRadius: theme.spacing(2),
              p: 6,
            }}
          >
            <Typography
              variant="headline-sm"
              display="flex"
              alignItems="center"
              gap={2}
              mb={4}
            >
              <IconCustomSparkles size={16} />
              AI Generated
            </Typography>
            {fetchAiKeywords && loadingKeywords && (
              <Typography
                variant="body-xl"
                display="flex"
                gap={2}
                my={3}
                alignItems="center"
                sx={{
                  color: theme.colors?.utility[500],
                }}
              >
                <CircularProgress
                  size={16}
                  sx={{
                    color: theme.colors?.utility[500],
                  }}
                />
                Loading...
              </Typography>
            )}

            {!fetchAiKeywords && (
              <Button
                disabled={!fetchAIGeneratedBrandDescription}
                variant="primary"
                sx={{
                  mt: 4,
                  color: theme.colors?.utility.blueberry,
                  backgroundColor: theme.colors?.primary.parchment,
                  borderRadius: theme.spacing(2),
                  border: 'none',
                  '&:hover': {
                    backgroundColor: theme.colors?.primary.parchment,
                    color: theme.colors?.utility.blueberry,
                  },
                }}
                endIcon={<IconCustomSparkles size={16} />}
                onClick={() => {
                  setFetchAiKeywords(true);
                  getAiGeneatedKeywords({
                    variables: {
                      description: aboutBrand,
                    },
                  }).then((data) => {
                    updateCompetitorDetail({
                      ...competitorDetail,
                      aiKeywords:
                        data.data?.getAIKeywordsForBrandDescription || [],
                    });
                  });
                }}
              >
                Generate
              </Button>
            )}
            <SocialMediaListeningKeywords
              componentProps={{
                container: {
                  sx: { my: 0 },
                },
                createKeyword: {
                  hideCreateKeyword: true,
                },
              }}
              keywords={competitorDetail.aiKeywords}
              updateKeywords={(aiKeywords) => {
                updateCompetitorDetail({
                  ...competitorDetail,
                  aiKeywords,
                });
              }}
            />
            <Box
              sx={{ borderTop: `1px solid rgba(250, 243, 236, 0.10)` }}
              my={6}
            />
            <Typography
              variant="headline-sm"
              display="flex"
              alignItems="center"
              gap={2}
              mb={3}
            >
              Manually add
            </Typography>
            <SocialMediaListeningKeywords
              componentProps={{
                container: {
                  sx: { my: 0 },
                },
              }}
              keywords={competitorDetail.keywords}
              updateKeywords={(keywords) => {
                updateCompetitorDetail({
                  ...competitorDetail,
                  keywords,
                });
              }}
            />
            <Box
              sx={{ borderTop: `1px solid rgba(250, 243, 236, 0.10)` }}
              my={6}
            />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body-xl" fontWeight={500}>
                Allow Plot to update this list to improve results
              </Typography>
              <Switch
                checked={competitorDetail.allowPlotToUpdateKeywords}
                onChange={() =>
                  updateCompetitorDetail({
                    ...competitorDetail,
                    allowPlotToUpdateKeywords:
                      !competitorDetail.allowPlotToUpdateKeywords,
                  })
                }
                sx={{
                  '& .MuiSwitch-thumb': {
                    backgroundColor: competitorDetail.allowPlotToUpdateKeywords
                      ? theme.colors?.primary.black
                      : theme.colors?.primary.parchment,
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: `${theme.colors?.utility[500]} !important`,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <RenderNextButton
        onNextClick={() => handleSubmit()}
        disabled={!isValidFormData || saving}
        loading={saving}
      />
    </Box>
  );
};
