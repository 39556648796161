import { NetworkStatus } from '@apollo/client';
import { Box, Checkbox, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { MoreMenuItem } from 'components/common/Menu';
import { TextSwitch } from 'components/common/TextSwitch/TextSwitch';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconCustomAiOrganizeSparkle } from 'components/icons/components/custom/IconCustomAiOrganizeSparkle';
import { IconCustomSmiley } from 'components/icons/components/custom/IconCustomSmiley';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconLinearChainlinkLink } from 'components/icons/components/linear/IconLinearChainlinkLink';
import { IconLinearClipboardText } from 'components/icons/components/linear/IconLinearClipboardText';
import { IconLinearGrid2 } from 'components/icons/components/linear/IconLinearGrid2';
import { IconLinearGroup } from 'components/icons/components/linear/IconLinearGroup';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconLinearTimer1 } from 'components/icons/components/linear/IconLinearTimer1';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineBulletedList } from 'components/icons/components/outline/IconOutlineBulletedList';
import { IconOutlineChart3 } from 'components/icons/components/outline/IconOutlineChart3';
import { IconOutlineLike } from 'components/icons/components/outline/IconOutlineLike';
import { IconOutlinePlay } from 'components/icons/components/outline/IconOutlinePlay';
import { IconOutlineStatus } from 'components/icons/components/outline/IconOutlineStatus';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import {
  SOCIAL_LISTENING_POST_VIEW_SETTING,
  SOCIAL_LISTENING_TOPIC_IDS_FILTER,
  SOCIAL_LISTENING_TOPIC_RELEVANCY_FILTERS,
  SocialMediaListeningPostsGridView,
  SocialMediaListeningPostsListView,
  useSLBrandPermissions,
  useSocialPostsList,
  UseSocialPostsListProps,
} from 'features/socialMediaListening';
import {
  SLPageBrandSelector,
  SocialMediaListeningWeeklySnapShotSummary,
} from 'features/socialMediaListening/components';
import { BrandParsedHours } from 'features/socialMediaListening/components/brandParsedHours';
import { FilterByOrganicPosts } from 'features/socialMediaListening/components/filterSelection/FilterBy/customFilters/filterByOrganicPosts';
import { FilterBySocialSignalDefinition } from 'features/socialMediaListening/components/filterSelection/FilterBy/customFilters/filterBySocialSignalDefinition';
import { SocialMediaListeningFilterSelection } from 'features/socialMediaListening/components/filterSelection/SocialMediaListeningFilterSelection';
import { FilterOption } from 'features/socialMediaListening/components/filterSelection/types';
import { SocialPostStatusData } from 'features/socialPost';
import {
  BrandSocialPostEngagementStatus,
  FollowersType,
  Platform,
  PlotFeature,
  Sentiment,
  SocialListeningPostsSortBy,
  SortOrder,
  useGetBrandForSocialMediaListeningQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { IconLinearDollarCircle } from 'components/icons/components/linear/IconLinearDollarCircle';
import { StyledSlider } from './styles';

const filterOptions: FilterOption[] = [
  {
    key: 'platforms',
    label: 'Platforms',
    icon: <IconLinearMonitorMobile size={16} />,
    options: [
      {
        render: 'TikTok',
        value: Platform.Tiktok,
      },
      {
        render: 'Instagram',
        value: Platform.Instagram,
      },
    ],
  },
  {
    key: 'followers',
    label: 'Followers',
    icon: <IconLinearUser size={16} />,
    options: [
      {
        render: '1-10k',
        value: FollowersType.Nano,
      },
      {
        render: '10k-100k',
        value: FollowersType.Micro,
      },
      {
        render: '100k-1M',
        value: FollowersType.Macro,
      },
      {
        render: '1M+',
        value: FollowersType.Mega,
      },
    ],
  },
  {
    key: 'sentiments',
    label: 'Sentiments',
    icon: <IconCustomSmiley size={16} />,
    options: [
      {
        render: 'Positive',
        value: Sentiment.Positive,
      },
      {
        render: 'Neutral',
        value: Sentiment.Neutral,
      },
      {
        render: 'Negative',
        value: Sentiment.Negative,
      },
      {
        render: 'None',
        value: Sentiment.None,
      },
    ],
  },
  {
    key: 'statuses',
    label: 'Status',
    icon: <IconOutlineStatus size={16} />,
    options: Object.keys(SocialPostStatusData).map((status) => ({
      render: (
        <Box
          sx={{
            my: 2,
            ...SocialPostStatusData[status as BrandSocialPostEngagementStatus]
              .sx,
          }}
        >
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[800]}
            fontWeight={600}
          >
            {SocialPostStatusData[status].label}
          </Typography>
        </Box>
      ),
      value: status,
    })),
  },
];

const sortOptions = [
  {
    key: SocialListeningPostsSortBy.CreatedAt,
    label: 'Recency',
    icon: <IconLinearTimer1 size={16} />,
  },
  {
    key: SocialListeningPostsSortBy.ConfidenceScore,
    label: 'Topic Relevancy',
    icon: <IconLinearChainlinkLink size={16} />,
  },
  {
    key: SocialListeningPostsSortBy.EngagementScore,
    label: 'Engagement Rate',
    icon: <IconOutlineLike size={16} />,
  },
  {
    key: SocialListeningPostsSortBy.TotalEngagementScore,
    label: 'Total Engagement',
    icon: <IconOutlineChart3 size={16} />,
  },
];

export const SocialMediaListeningPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);
  const signalMiningEnabled = isFeatureEnabled(PlotFeature.SignalMining);

  const [filterByOptions, setFilterByOptions] = useState(filterOptions);

  const { brandId = '' } = useParams();
  const { hasFullAccess } = useSLBrandPermissions({
    brandId,
  });

  const { value: view, setValue: setView } = useLocalStorage<'grid' | 'list'>(
    SOCIAL_LISTENING_POST_VIEW_SETTING + brandId,
    'grid',
  );
  const { value: filteredTopics, setValue: setFilteredTopics } =
    useLocalStorage<string[]>(SOCIAL_LISTENING_TOPIC_IDS_FILTER + brandId, []);

  const {
    value: topicRelevancyFilterData,
    setValue: setTopicRelevancyFilterData,
  } = useLocalStorage<number>(
    SOCIAL_LISTENING_TOPIC_RELEVANCY_FILTERS + brandId,
    0.5,
  );
  const [topicRelevancy, setTopicRelevancy] = useState(
    topicRelevancyFilterData,
  );
  const {
    data: brandData,
    loading: brandQueryLoading,
    networkStatus,
  } = useGetBrandForSocialMediaListeningQuery({
    variables: {
      brandId,
    },
    skip: !brandId,
  });
  const topics = brandData?.brand.topics ?? [];
  const signalDefinitions = brandData?.brand.signalDefinitions || [];

  const [appliedFilters, setAppliedFilters] = useState<{
    [key: string]:
      | string
      | (string | Date | null)[]
      | boolean
      | undefined
      | number;
  }>({});

  const [sortBy, setSortBy] = useState(sortOptions[0].key);
  const [sortOrder, setSortOrder] = useState(SortOrder.Desc);

  const [localMinimumPlayCount, setLocalMinimumPlayCount] = useState(10000);

  const debouncedApiCall = useRef(
    debounce((value: number) => {
      setAppliedFilters((prev) => ({
        ...prev,
        minimumPlayCount: value,
      }));
    }, 300),
  ).current;

  const handleMinimumPlayCountChange = useCallback(
    (value: number) => {
      setLocalMinimumPlayCount(value);
      debouncedApiCall(value);
    },
    [debouncedApiCall],
  );

  useEffect(() => {
    const sliderRenderForTopicRelevancy = (
      <Box sx={{ width: '100%', pl: 4, pr: 2 }}>
        <StyledSlider
          defaultValue={0.5}
          valueLabelDisplay="auto"
          marks={[
            ...Array.from({ length: 6 }, (_, i) => ({
              value: 0.5 + i * 0.1,
              label: `${(0.5 + i * 0.1) * 100}%`,
            })),
            { value: 1, label: '100%' },
          ]}
          step={0.1}
          min={0.5}
          max={1}
          onChange={(_, value) => {
            if (typeof value === 'number') {
              setTopicRelevancy(value);
            }
          }}
          value={topicRelevancy}
          valueLabelFormat={(value) => `${value * 100}%`}
        />
      </Box>
    );

    const sliderRendererForMinimumPlayCount = (
      <Box sx={{ width: '100%', pl: 4, pr: 2 }}>
        <StyledSlider
          defaultValue={100000}
          valueLabelDisplay="auto"
          step={10000}
          min={10000}
          max={100_000_000}
          marks={[
            { value: 10000, label: '10k' },
            { value: 100000, label: '100k' },
            { value: 1000000, label: '1M' },
            { value: 5000000, label: '5M' },
            { value: 10000000, label: '10M' },
            { value: 100000000, label: '100M' },
          ]}
          onChange={(_, value) => {
            if (typeof value === 'number') {
              handleMinimumPlayCountChange(value);
            }
          }}
          value={localMinimumPlayCount}
          valueLabelFormat={(value) => `${formatBigNumber(value)}`}
        />
      </Box>
    );

    setTopicRelevancyFilterData(topicRelevancy);

    if (brandData?.brand) {
      const filterOptionForCategories = {
        key: 'categories',
        label: 'Categories',
        icon: <IconCustomAiOrganizeSparkle size={16} />,
        isMultiSelect: false,
        options: [
          {
            render: (
              <FilterBySocialSignalDefinition
                signalDefinitions={signalDefinitions}
                onValueUpdated={(
                  signalDefinitionIds: string[],
                  signalDefinitionOptionIds: string[],
                ) => {
                  setAppliedFilters((prev) => ({
                    ...prev,
                    signalDefinitionIds,
                    signalOptionIds: signalDefinitionOptionIds,
                  }));
                }}
              />
            ),
            value: '',
          },
        ],
      };

      setFilterByOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];

        // Topic relevancy filter
        const topicRelevancyOptionIndex = updatedOptions.findIndex(
          (o) => o.key === 'topicRelevancy',
        );
        if (topicRelevancyOptionIndex !== -1) {
          updatedOptions[topicRelevancyOptionIndex] = {
            ...updatedOptions[topicRelevancyOptionIndex],
            options: [
              {
                render: sliderRenderForTopicRelevancy,
                value: '',
              },
            ],
          };
        } else {
          updatedOptions.push({
            key: 'topicRelevancy',
            label: 'Topic Relevancy',
            icon: <IconLinearGroup size={16} />,
            isMultiSelect: false,
            options: [
              {
                render: sliderRenderForTopicRelevancy,
                value: '',
              },
            ],
          });
        }

        // Organic/paid filter
        const organicOptionIndex = updatedOptions.findIndex(
          (o) => o.key === 'isOrganic',
        );
        if (organicOptionIndex !== -1) {
          updatedOptions[organicOptionIndex] = {
            ...updatedOptions[organicOptionIndex],
            options: [
              {
                render: (
                  <FilterByOrganicPosts
                    onValueUpdated={(value) => {
                      setAppliedFilters((prev) => ({
                        ...prev,
                        isOrganic: value as boolean | undefined,
                      }));
                    }}
                  />
                ),
                value: '',
              },
            ],
          };
        } else {
          updatedOptions.push({
            key: 'isOrganic',
            label: 'Organic/paid',
            icon: <IconLinearDollarCircle size={16} />,
            isMultiSelect: false,
            options: [
              {
                render: (
                  <FilterByOrganicPosts
                    onValueUpdated={(value) => {
                      setAppliedFilters((prev) => ({
                        ...prev,
                        isOrganic: value as boolean | undefined,
                      }));
                    }}
                  />
                ),
                value: '',
              },
            ],
          });
        }

        // Signal mining filter
        if (signalMiningEnabled) {
          const signalMiningOptionIndex = updatedOptions.findIndex(
            (o) => o.key === 'categories',
          );
          if (signalMiningOptionIndex !== -1) {
            updatedOptions[signalMiningOptionIndex] = filterOptionForCategories;
          } else {
            updatedOptions.push(filterOptionForCategories);
          }
        }

        // Minimum play count filter
        const minimumPlayCountOptionIndex = updatedOptions.findIndex(
          (o) => o.key === 'minimumPlayCount',
        );

        if (minimumPlayCountOptionIndex !== -1) {
          updatedOptions[minimumPlayCountOptionIndex] = {
            ...updatedOptions[minimumPlayCountOptionIndex],
            options: [
              {
                render: sliderRendererForMinimumPlayCount,
                value: '',
              },
            ],
          };
        } else {
          updatedOptions.push({
            key: 'minimumPlayCount',
            label: 'Minimum Play Count',
            icon: <IconOutlinePlay size={16} />,
            isMultiSelect: false,
            options: [
              {
                render: sliderRendererForMinimumPlayCount,
                value: '',
              },
            ],
          });
        }

        return updatedOptions;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topicRelevancy,
    brandData?.brand,
    handleMinimumPlayCountChange,
    localMinimumPlayCount,
  ]);

  useEffect(() => {
    if (!brandData?.brand && networkStatus === NetworkStatus.ready) {
      // Data for brands is not available
      navigate(PlotRoutes.socialListeningIntro(), {
        replace: true,
      });
    }
  }, [brandData, networkStatus]); // eslint-disable-line

  const currentBrand = brandData?.brand;

  const { loading, refetch, socialPostsData, fetchMorePostSuggestions } =
    useSocialPostsList({
      brandId: currentBrand?.id || '',
      sortBy,
      sortOrder,
      filters: {
        ...appliedFilters,
        topicIds: filteredTopics,
        topicRelevancy,
      },
    });

  useEffect(() => {
    if (currentBrand) {
      refetch();
    }
  }, [appliedFilters, filteredTopics]); // eslint-disable-line

  const socialPosts = socialPostsData?.getPaginatedSocialPosts.data;
  const hasNextPage =
    socialPostsData?.getPaginatedSocialPosts.pageInfo.hasNextPage;

  const {
    setRenderTitle,
    setHeaderSx,
    addRenderMenuExtraLeft,
    removeRenderMenuExtraLeft,
  } = useCustomHeaderContext();

  useEffect(() => {
    const renderMenuExtraLeft = () => (
      <SocialListeningUserPermissionsView brandId={brandId} />
    );

    addRenderMenuExtraLeft(renderMenuExtraLeft, 1);

    setHeaderSx({
      borderBottom: `1px solid transparent`,
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.black}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
      removeRenderMenuExtraLeft(renderMenuExtraLeft);
    };
  }, [location.pathname]); // eslint-disable-line

  if (brandQueryLoading) {
    return <Box>Loading...</Box>;
  }

  if (!brandData?.brand) {
    return null;
  }

  return (
    <Box m={theme.spacing(16, 8, 8)}>
      {/* <SocialMediaListeningZeroStateView /> */}
      <Box py={4} gap={4} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SLPageBrandSelector
            generateUrl={(brandId) =>
              PlotRoutes.socialListeningForBrand(brandId)
            }
          />
          <Box
            marginLeft="auto"
            display="flex"
            alignItems="center"
            columnGap={2}
          >
            <BrandParsedHours />
            {currentBrand && (
              <Box
                borderRadius={10}
                bgcolor={theme.colors?.utility[275]}
                padding={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 'center',
                }}
                component="button"
                onClick={() => {
                  navigate(
                    PlotRoutes.socialListeningSettings({
                      brandId: currentBrand.id,
                    }),
                  );
                }}
              >
                <IconBoldSettings2
                  size={22}
                  color={theme.colors?.utility[600]}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
          Weekly snapshot
        </Typography>

        <SocialMediaListeningWeeklySnapShotSummary
          brandId={currentBrand?.id || ''}
        />
      </Box>

      <Box>
        <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
          Recent Posts
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={4}
        >
          {/* Replace the <Box /> with the SocialMediaListeningSearchInput component when it is ready */}
          <Box />
          {/* <SocialMediaListeningSearchInput onInputChange={(query) => {}} /> */}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={4}
            gap={4}
          >
            <TextSwitch
              textOptionLeft={<IconLinearGrid2 size={14} />}
              textOptionRight={<IconOutlineBulletedList size={14} />}
              selectedOptionTextColor={theme.colors?.primary.white}
              sx={{
                bgcolor: theme.colors?.utility[275],
                height: theme.spacing(10),
                width: theme.spacing(20),
                p: theme.spacing(0.5),
                borderRadius: theme.spacing(25),
              }}
              isLeftOptionSelected={view === 'grid'}
              onClick={(isLeftOptionSelected) =>
                setView(isLeftOptionSelected ? 'grid' : 'list')
              }
              componentProps={{
                text: { sx: { color: theme.colors?.primary.black } },
                indicator: {
                  sx: {
                    bgcolor: theme.colors?.primary.black,
                    borderRadius: theme.spacing(25),
                    height: '38px',
                  },
                },
              }}
            />
            <ContextMenu
              sx={{
                '& .MuiMenu-list': {
                  padding: `${theme.spacing(3)} !important`,
                  gap: `${theme.spacing(1)} !important`,
                },
                '& li': {
                  p: theme.spacing(1, 2),
                },
                '& li:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              renderButton={() => (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: theme.spacing(3),
                    backgroundColor: theme.colors?.utility[275],
                    p: theme.spacing(3, 3),
                  }}
                >
                  <IconLinearClipboardText size={16} />
                  <Typography variant="body-xl" fontWeight={500}>
                    {!filteredTopics.length ||
                    filteredTopics.length === topics.length
                      ? 'All Topics Selected'
                      : `${filteredTopics.length} ${
                          filteredTopics.length > 1 ? 'Topics' : 'Topic'
                        } Selected`}
                  </Typography>
                </Box>
              )}
              options={[
                ...topics.map((topic) => ({
                  renderOption: () => (
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      width="100%"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFilteredTopics((prev) => {
                          if (prev.includes(topic.id)) {
                            return prev.filter((id) => id !== topic.id);
                          }
                          return [...prev, topic.id];
                        });
                      }}
                    >
                      <Checkbox
                        disableRipple
                        checked={filteredTopics.includes(topic.id)}
                        sx={{
                          p: 1,
                          color: theme.colors?.primary.black,
                          '&.MuiCheckbox-root': {
                            color: theme.colors?.primary.black,
                          },
                        }}
                      />
                      <Typography variant="body-lg">{topic.name}</Typography>
                    </Box>
                  ),
                })),
                ...(hasFullAccess
                  ? [
                      {
                        renderOption: () => (
                          <Box
                            sx={{
                              width: '100%',
                              my: theme.spacing(2),
                              borderTop: `1px solid ${theme.colors?.utility[500]}`,
                            }}
                          />
                        ),
                      },
                      {
                        renderOption: () => (
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <MoreMenuItem
                              disableRipple
                              sx={{
                                minWidth: 240,
                                width: '100%',
                              }}
                              label={
                                <Typography
                                  display="inline-block"
                                  variant="body-md"
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    borderRadius: theme.spacing(2),
                                    p: theme.spacing(3, 2),
                                    '&:hover': {
                                      backgroundColor:
                                        theme.colors?.utility[275],
                                    },
                                  }}
                                >
                                  Create a New Topic
                                  <IconLinearArrowRight size={16} />
                                </Typography>
                              }
                            >
                              <Box
                                sx={{
                                  width: 270,
                                  py: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1,
                                }}
                              >
                                <Link
                                  to={PlotRoutes.socialListeningOnboarding(
                                    'topic',
                                    brandId,
                                  )}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 2,
                                      padding: theme.spacing(3),
                                      borderRadius: theme.spacing(3),
                                      '&:hover': {
                                        backgroundColor:
                                          theme.colors?.utility[275],
                                      },
                                    }}
                                  >
                                    <Typography
                                      variant="body-lg"
                                      fontWeight={500}
                                    >
                                      Topic Insights
                                    </Typography>
                                    <Typography
                                      variant="body-sm"
                                      fontWeight={500}
                                      color={theme.colors?.utility[900]}
                                    >
                                      Track conversations across customized
                                      topics
                                    </Typography>
                                  </Box>
                                </Link>
                                {creatorTrackingEnabled && (
                                  <Link
                                    to={PlotRoutes.socialListeningOnboarding(
                                      'creator',
                                      brandId,
                                    )}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        padding: theme.spacing(3),
                                        borderRadius: theme.spacing(3),
                                        '&:hover': {
                                          backgroundColor:
                                            theme.colors?.utility[275],
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="body-lg"
                                        fontWeight={500}
                                      >
                                        Creator Focus
                                      </Typography>
                                      <Typography
                                        variant="body-sm"
                                        fontWeight={500}
                                        color={theme.colors?.utility[900]}
                                      >
                                        Track specific creators discussing your
                                        key topics
                                      </Typography>
                                    </Box>
                                  </Link>
                                )}
                              </Box>
                            </MoreMenuItem>
                          </Box>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
            <SocialMediaListeningFilterSelection
              brandId={currentBrand?.id || ''}
              filterBy={filterByOptions}
              sortBy={sortOptions}
              onFilterUpdated={(filter) => {
                setAppliedFilters(filter);
              }}
              onSortUpdated={(key, order) => {
                setSortBy(key as SocialListeningPostsSortBy);
                setSortOrder(order);
              }}
            />
          </Box>
        </Box>
      </Box>

      <InfiniteScroll
        hasMore={hasNextPage}
        initialLoad={false}
        loadMore={fetchMorePostSuggestions}
        threshold={1024}
        getScrollParent={() =>
          document.querySelector(
            '.collection-cjb-window-view-scrollable-container',
          )
        }
      >
        {socialPosts?.length && socialPosts?.length > 0 ? (
          <Box
            sx={{
              maxWidth: `calc(100vw - ${theme.spacing(16)})`,
              overflowX: 'auto',
            }}
          >
            {view === 'grid' ? (
              <SocialMediaListeningPostsGridView
                brandId={currentBrand?.id || ''}
                socialPosts={socialPosts}
                searchCriteria={window.btoa(
                  JSON.stringify({
                    brandId: currentBrand?.id ?? '',
                    filters: { ...appliedFilters, topicIds: filteredTopics },
                    sortBy,
                    sortOrder,
                  } as UseSocialPostsListProps),
                )}
                sortBy={sortBy}
              />
            ) : (
              <SocialMediaListeningPostsListView
                brandId={currentBrand?.id || ''}
                socialPosts={socialPosts}
                searchCriteria={window.btoa(
                  JSON.stringify({
                    brandId: currentBrand?.id ?? '',
                    filters: { ...appliedFilters, topicIds: filteredTopics },
                    sortBy,
                    sortOrder,
                  } as UseSocialPostsListProps),
                )}
              />
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <Typography variant="body-lg" color={theme.colors?.utility[700]}>
              {loading ? 'Loading...' : 'No posts'}
            </Typography>
          </Box>
        )}
      </InfiniteScroll>
    </Box>
  );
};
