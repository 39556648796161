import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { CollectionFragmentCollectionBreadcrumbsFragment } from 'graphql/generated';
import { Fragment, ReactNode, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentCollectionBreadcrumbs on CollectionModel {
    id
    breadcrumbsFromRoot {
      id
      name
    }
  }
`;

export type CollectionBreadcrumbsProps = {
  collection: CollectionFragmentCollectionBreadcrumbsFragment;
  delimiter?: ReactNode;
  // only ellipse when breadcrumbs length are longer than this value
  ellipseAtLength?: number;
  ellipse?: boolean;
  hideCurrentCollection?: boolean;
  sx?: SxProps;
  componentProps?: {
    text?: {
      sx?: SxProps;
      lastLocationSx?: SxProps;
    };
    breadcrumbContainer?: {
      sx?: SxProps;
    };
    breadcrumbText?: {
      sx?: SxProps;
    };
  };
  onCollectionClick?: (collectionId: string) => void;
  wrapBreadcrumbs?: boolean;
};

const CollectionClickHandler = ({
  collectionId,
  children,
  onClick,
  sx,
}: {
  collectionId: string;
  children: ReactNode;
  onClick?: (collectionId: string) => void;
  sx?: SxProps;
}) => {
  const ref = useRef<HTMLAnchorElement>(null);

  const location = useLocation();

  if (onClick) {
    return (
      <Box
        onClick={() => onClick(collectionId)}
        sx={{
          cursor: 'pointer',
          display: 'inline',
          overflow: 'hidden',
          ...(sx || {}),
        }}
        ref={ref}
      >
        {children}
      </Box>
    );
  }

  return (
    <Link
      key={collectionId}
      to={PlotRoutes.collection(collectionId)}
      state={location.state}
      ref={ref}
    >
      {children}
    </Link>
  );
};

export const CollectionBreadcrumbs = (props: CollectionBreadcrumbsProps) => {
  const {
    collection,
    delimiter = (
      <IconLinearArrowRight size={14} color={theme.colors?.utility[700]} />
    ),
    ellipseAtLength,
    ellipse,
    hideCurrentCollection,
    sx,
    componentProps,
    onCollectionClick,
    wrapBreadcrumbs = true,
  } = props;

  const breadCrumbs = useMemo(() => {
    if (hideCurrentCollection && collection.breadcrumbsFromRoot.length > 0) {
      return [...collection.breadcrumbsFromRoot].splice(
        0,
        collection.breadcrumbsFromRoot.length - 1,
      );
    }

    return [...collection.breadcrumbsFromRoot];
  }, [collection.breadcrumbsFromRoot, hideCurrentCollection]);

  if (breadCrumbs.length === 0) {
    return null;
  }

  const lastItem = breadCrumbs[breadCrumbs.length - 1];
  const previousItems = breadCrumbs.slice(0, breadCrumbs.length - 1);

  return (
    <Tooltip
      title={collection.breadcrumbsFromRoot
        .map((b) => b.name)
        .reduce((a, b) => `${a}/${b}`)}
    >
      <Box
        key={
          collection.breadcrumbsFromRoot.map((b) => b.id).join('-') +
          collection.id
        }
        className="bcc"
        sx={{
          display: 'flex',
          flexWrap: wrapBreadcrumbs ? 'wrap' : 'nowrap',
          overflow: wrapBreadcrumbs ? 'visible' : 'hidden',
          textOverflow: wrapBreadcrumbs ? 'clip' : 'ellipsis',
          gap: theme.spacing(0, 2),
          alignItems: 'center',
          color: theme.colors?.utility[700],
          ...sx,
        }}
      >
        {/* Previous items */}
        {/* 1st scenario: breadcrumb length > ellipseAtLength -- render first item + '...' */}
        {previousItems.length > 0 &&
          (ellipse ||
          (ellipseAtLength && ellipseAtLength < breadCrumbs.length) ? (
            <>
              <CollectionClickHandler
                key={0}
                collectionId={breadCrumbs[0].id}
                onClick={onCollectionClick}
              >
                <Typography
                  sx={[
                    {
                      ...theme.typography['headline-xs'],
                      color: theme.colors?.utility[700],
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                    componentProps?.breadcrumbText?.sx || {},
                    ...(componentProps &&
                    componentProps.text &&
                    Array.isArray(componentProps.text.sx)
                      ? componentProps.text.sx
                      : [componentProps?.text?.sx]),
                  ]}
                >
                  {breadCrumbs[0].name}
                </Typography>
              </CollectionClickHandler>
              {delimiter}
              <Typography
                sx={{
                  ...theme.typography['headline-xs'],
                  color: theme.colors?.utility[700],
                  ...componentProps?.text?.sx,
                }}
              >
                ...
              </Typography>
              {delimiter}
            </>
          ) : (
            // 2nd scenario: breadcrumb length <= ellipseAtLength -- render all items
            <>
              {previousItems.map((item) => (
                <Fragment key={item.id}>
                  <CollectionClickHandler
                    collectionId={item.id}
                    onClick={onCollectionClick}
                  >
                    <Typography
                      sx={[
                        {
                          ...theme.typography['headline-xs'],
                          color: theme.colors?.utility[700],
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        componentProps?.breadcrumbText?.sx || {},
                        ...(componentProps &&
                        componentProps.text &&
                        Array.isArray(componentProps.text.sx)
                          ? componentProps.text.sx
                          : [componentProps?.text?.sx]),
                      ]}
                    >
                      {item.name}
                    </Typography>
                  </CollectionClickHandler>
                  {delimiter}
                </Fragment>
              ))}
            </>
          ))}

        {/* Last item */}
        <CollectionClickHandler
          collectionId={lastItem.id}
          onClick={onCollectionClick}
          sx={{
            flexShrink: 0,
          }}
        >
          <Typography
            sx={[
              {
                ...theme.typography['headline-xs'],
                color: theme.colors?.primary.black,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              ...(componentProps &&
              componentProps.text &&
              Array.isArray(componentProps.text.sx)
                ? componentProps.text.sx
                : [componentProps?.text?.sx]),
              ...(componentProps &&
              componentProps.text &&
              Array.isArray(componentProps.text.lastLocationSx)
                ? componentProps.text.lastLocationSx
                : [componentProps?.text?.lastLocationSx]),
            ]}
          >
            {lastItem.name}
          </Typography>
        </CollectionClickHandler>
      </Box>
    </Tooltip>
  );
};
