import { Box, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconLinearArrowUp } from 'components/icons/components/linear/IconLinearArrowUp';
import { MetricsTimeRange } from 'graphql/generated';
import { theme } from 'styles/theme';
import {
  generateRankingByNumber,
  getDurationNameByMetricsRange,
} from '../../utils';
import { SocialMediaAnalyticsCompetitorsSentimentProps } from './types';

export const SocialMediaAnalyticsCompetitorsSentiment = ({
  onBrandClicked,
  data,
  currentBrandName,
  selectedTimeRange,
}: SocialMediaAnalyticsCompetitorsSentimentProps) => {
  const differenceFromLastRank =
    data.currentDurationRank && data.previousDurationRank
      ? data.previousDurationRank - data.currentDurationRank
      : 0;
  const maxPositiveCount = Math.max(...data.data.map((x) => x.positiveCount));

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        border: `1px solid ${theme.colors?.utility[300]}`,
        p: 6,
        borderRadius: 6,
      }}
    >
      <Typography
        variant="headline-md"
        fontSize={theme.spacing(5)}
        color={theme.colors?.primary.black}
      >
        Sentiment
      </Typography>

      {selectedTimeRange !== MetricsTimeRange.AllTime && (
        <Typography
          variant="headline-md"
          fontSize={theme.spacing(7)}
          color={theme.colors?.utility[700]}
        >
          {data.currentDurationRank ? (
            <>
              {currentBrandName} is the{' '}
              <Box component="span" color={theme.colors?.utility[800]}>
                {generateRankingByNumber(data.currentDurationRank)}
              </Box>{' '}
              most positively talked about brand with{' '}
              <Box component="span" color={theme.colors?.utility[800]}>
                {Number(
                  data.data.find((x) => x.brand?.name === currentBrandName)
                    ?.positivePercentage || 0,
                ).toFixed(1)}
                %
              </Box>{' '}
              positive sentiment
            </>
          ) : (
            <>
              {currentBrandName} was not mentioned in the{' '}
              {getDurationNameByMetricsRange(selectedTimeRange)}. We still love
              you! ❤️
            </>
          )}
        </Typography>
      )}

      {differenceFromLastRank !== 0 && (
        <Typography
          display="flex"
          alignItems="center"
          gap={1}
          mt={3}
          variant="body-xl"
          fontWeight={600}
          fontSize={theme.spacing(5)}
          color={
            differenceFromLastRank > 0
              ? theme.colors?.utility['green-3-new']
              : theme.colors?.utility['pink-3']
          }
        >
          <IconLinearArrowUp
            size={16}
            style={{
              transform:
                differenceFromLastRank < 0 ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
          {differenceFromLastRank > 0 ? 'Up' : 'Down'}{' '}
          {Math.abs(differenceFromLastRank)}{' '}
          {Math.abs(differenceFromLastRank) > 1 ? 'places' : 'place'} since{' '}
          {getDurationNameByMetricsRange(selectedTimeRange)}
        </Typography>
      )}

      <Box>
        <Box display="flex" flexDirection="column" gap={4} alignItems="center">
          {!data.data.length ? (
            <Box textAlign="center" my={20}>
              <Typography variant="body-lg" color={theme.colors?.utility[700]}>
                No data available
              </Typography>
            </Box>
          ) : (
            <Box
              mt={4}
              display="flex"
              flexDirection="column"
              gap={2}
              alignItems="center"
              width="100%"
              justifyContent="center"
            >
              {data.data.map((sentimentData, index) => {
                if (sentimentData.positiveCount === 0) {
                  return null;
                }

                return (
                  <Box
                    key={sentimentData.brand?.id}
                    width="100%"
                    display="flex"
                    gap={8}
                    justifyContent="space-between"
                    sx={{
                      p: 2,
                      borderRadius: theme.spacing(1),
                      '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: theme.colors?.utility[275],
                      },
                    }}
                    onClick={() => {
                      onBrandClicked(sentimentData.brand.id);
                    }}
                  >
                    <Typography
                      variant="body-xl"
                      fontWeight={500}
                      color={theme.colors?.utility[900]}
                      minWidth={theme.spacing(40)}
                      maxWidth={theme.spacing(40)}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {index + 1}. {sentimentData.brand?.name}
                    </Typography>
                    <Box
                      sx={{
                        height: theme.spacing(8),
                        display: 'flex',
                        flex: 1,
                        gap: 1,
                        width: '100%',
                      }}
                    >
                      <Tooltip
                        title={`${sentimentData.positiveCount} positive ${
                          sentimentData.positiveCount > 1
                            ? 'mentions'
                            : 'mention'
                        }`}
                      >
                        <Box
                          sx={{
                            overflow: 'hidden',
                            borderRadius: theme.spacing(1),
                            width: `${
                              (sentimentData.positiveCount / maxPositiveCount) *
                              100
                            }%`,
                            height: '100%',
                            backgroundColor: theme.colors?.utility['green-2'],
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {Number(
                            (sentimentData.positiveCount / maxPositiveCount) *
                              100,
                          ) >= 10 && (
                            <Typography variant="body-xl" fontWeight={500}>
                              {sentimentData.positiveCount}
                            </Typography>
                          )}
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
