import { MetricsTimeRange } from 'graphql/generated';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

type ChartData = {
  X: string;
  Y: number;
};

type Props = {
  dailyChartData: ChartData[];
  timeRange: MetricsTimeRange;
};

export const useGraphDataMaker = ({
  dailyChartData: chartData,
  timeRange,
}: Props) => {
  const [dailyData, setDailyData] = React.useState<ChartData[]>(chartData);

  const updateDailyData = (emptyChartData: ChartData[]) => {
    // fill the empty values with the data from the dailyChartData
    const updatedData = emptyChartData.map((emptyData) => {
      const data = chartData.find((chartData) =>
        moment(chartData.X).isSame(moment(emptyData.X), 'day'),
      );

      return data || emptyData;
    });

    setDailyData(updatedData);
  };

  useEffect(() => {
    // create empty data array
    // eslint-disable-next-line default-case
    switch (timeRange) {
      case MetricsTimeRange.OneWeek: {
        const chartData = Array.from({ length: 7 }, (_, i) => ({
          X: moment()
            .subtract(7 - i, 'days')
            .toISOString(),
          Y: 0,
        }));

        updateDailyData(chartData);
        break;
      }

      case MetricsTimeRange.OneMonth: {
        const chartData = Array.from({ length: 30 }, (_, i) => ({
          X: moment()
            .subtract(29 - i, 'days')
            .toISOString(),
          Y: 0,
        }));

        updateDailyData(chartData);
        break;
      }

      case MetricsTimeRange.ThreeMonths: {
        const chartData = Array.from({ length: 90 }, (_, i) => ({
          X: moment()
            .subtract(89 - i, 'days')
            .toISOString(),
          Y: 0,
        }));

        updateDailyData(chartData);
        break;
      }

      case MetricsTimeRange.SixMonths: {
        const chartData = Array.from({ length: 180 }, (_, i) => ({
          X: moment()
            .subtract(179 - i, 'days')
            .toISOString(),
          Y: 0,
        }));

        updateDailyData(chartData);
        break;
      }

      case MetricsTimeRange.OneYear: {
        const chartData = Array.from({ length: 365 }, (_, i) => ({
          X: moment()
            .subtract(364 - i, 'days')
            .toISOString(),
          Y: 0,
        }));

        updateDailyData(chartData);
        break;
      }
    }
  }, [timeRange, chartData]);

  const groupedDataByWeek = useMemo(() => {
    return dailyData.reduce((acc, curr) => {
      const week = moment(curr.X).isoWeek();
      const year = moment(curr.X).isoWeekYear();
      const weekStart = moment()
        .isoWeek(week)
        .isoWeekYear(year)
        .startOf('isoWeek');
      const weekEnd = moment().isoWeek(week).isoWeekYear(year).endOf('isoWeek');

      const weekLabel = `${weekStart.format('MMM D')} - ${weekEnd.format(
        'MMM D',
      )}`;

      if (!acc[weekLabel]) {
        acc[weekLabel] = 0;
      }

      acc[weekLabel] += curr.Y;

      return acc;
    }, {});
  }, [dailyData]);

  const groupedDataByMonth = useMemo(() => {
    return dailyData.reduce((acc, curr) => {
      const month = moment(curr.X).month();
      const year = moment(curr.X).year();
      const monthStart = moment().month(month).year(year).startOf('month');

      const monthLabel = monthStart.format('MMM');

      if (!acc[monthLabel]) {
        acc[monthLabel] = 0;
      }

      acc[monthLabel] += curr.Y;

      return acc;
    }, {});
  }, [dailyData]);

  const groupedDataByDay = useMemo(() => {
    return dailyData.reduce((acc, curr) => {
      const day = moment(curr.X).dayOfYear();
      const year = moment(curr.X).year();
      const dayStart = moment().dayOfYear(day).year(year).startOf('day');

      const dayLabel = dayStart.format('MMM D');

      if (!acc[dayLabel]) {
        acc[dayLabel] = 0;
      }

      acc[dayLabel] += curr.Y;

      return acc;
    }, {});
  }, [dailyData]);

  const preparedChartData = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (timeRange) {
      case MetricsTimeRange.OneWeek: {
        return Object.keys(groupedDataByDay).map(
          (key) =>
            ({
              X: key,
              Y: groupedDataByDay[key],
            } as ChartData),
        );
      }
      case MetricsTimeRange.OneMonth: {
        return Object.keys(groupedDataByWeek).map(
          (key) =>
            ({
              X: key,
              Y: groupedDataByWeek[key],
            } as ChartData),
        );
      }
      case MetricsTimeRange.ThreeMonths: {
        return Object.keys(groupedDataByMonth).map(
          (key) =>
            ({
              X: key,
              Y: groupedDataByMonth[key],
            } as ChartData),
        );
      }
      case MetricsTimeRange.SixMonths: {
        return Object.keys(groupedDataByMonth).map(
          (key) =>
            ({
              X: key,
              Y: groupedDataByMonth[key],
            } as ChartData),
        );
      }
      case MetricsTimeRange.OneYear: {
        return Object.keys(groupedDataByMonth).map(
          (key) =>
            ({
              X: key,
              Y: groupedDataByMonth[key],
            } as ChartData),
        );
      }
    }
  }, [timeRange, groupedDataByDay, groupedDataByWeek, groupedDataByMonth]);

  return {
    groupedDataByDay,
    groupedDataByWeek,
    groupedDataByMonth,
    chartData: preparedChartData,
  };
};
