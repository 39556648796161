import { gql } from '@apollo/client';
import { AvatarGroupProps, SxProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { UserFragmentAvatarGroupFragment } from 'graphql/generated';

export const USER_FRAGMENT_AVATAR_GROUP = gql`
  fragment UserFragmentAvatarGroup on UserProfileModel {
    id
    email
    firstName
    lastName
    avatarUrl
    isDisabled
    hasSignedUp
    shouldBeCountedTowardPaywall
  }
`;

type AvatarGroupVariant = 'overlap' | 'non-overlap';

export type AvatarGroupType = {
  users: UserFragmentAvatarGroupFragment[];
  avatarSize?: number;
  variant?: AvatarGroupVariant;
  showLeftNumber?: boolean;
  hideAdditionalAvatarTooltip?: boolean;
  additionalAvatarSx?: SxProps;
  avatarConditionalSx?: (user: UserFragmentAvatarGroupFragment) => SxProps;
  customAvatarTooltip?: (
    user: UserFragmentAvatarGroupFragment,
    children: ReactElement<any, any>,
  ) => ReactNode;
} & Pick<AvatarGroupProps, 'max' | 'sx'>;
