import { Box, Dialog, Typography } from '@mui/material';
import { CollectionBreadcrumbs } from 'features/collection/components';
import { useGetCollectionForMoveToAnotherCollectionQuery } from 'graphql/generated';
import { SyntheticEvent } from 'react';
import { theme } from 'styles/theme';
import { CollectionMoveToAnotherCollectionLayout } from './CollectionMoveToAnotherCollectionLayout';
import { CollectionMoveToAnotherCollectionProps } from './types';

export const CollectionMoveToAnotherCollection = ({
  collectionId,
  isOpen,
  onClose,
  onMove,
}: CollectionMoveToAnotherCollectionProps) => {
  const { data } = useGetCollectionForMoveToAnotherCollectionQuery({
    variables: {
      collectionId,
    },
    skip: !collectionId,
  });
  const collectionToMove = data?.collection;

  const handleClose = (e: SyntheticEvent) => {
    // Stop event propagation in case this component is rendered
    // from within another component that handles click (e.g. link)
    e?.stopPropagation();

    onClose();
  };

  if (!collectionToMove) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: theme.spacing(6),
          border: 'none',
        },
      }}
      PaperProps={{
        // Stop event propagation in case this component is rendered
        // from within another component that handles click (e.g. link)
        onClick: (e) => e.stopPropagation(),
      }}
      disableRestoreFocus
    >
      <CollectionMoveToAnotherCollectionLayout
        collectionIdToMove={collectionToMove.id}
        onSelectDestinationCollection={(destinationCollection) =>
          onMove([collectionId], destinationCollection)
        }
      >
        <Typography variant="headline-lg" fontSize={theme.spacing(7)}>
          Move "{collectionToMove.name}"
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          alignItems="center"
          mb={2}
        >
          <Typography
            variant="body-lg"
            fontWeight={600}
            color={theme.colors?.utility[800]}
          >
            Current Location
          </Typography>

          <CollectionBreadcrumbs
            collection={collectionToMove}
            ellipseAtLength={2}
            onCollectionClick={() => {}}
            sx={{
              ...theme.typography['body-md'],
              color: theme.colors?.utility[800],
              backgroundColor: 'rgba(35, 6, 3, 0.10)',
              p: theme.spacing(1, 4),
              borderRadius: 4,
              width: 'fit-content',
            }}
          />
        </Box>
      </CollectionMoveToAnotherCollectionLayout>
    </Dialog>
  );
};
