import { Box, Button, IconButton, Typography } from '@mui/material';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineInfoCircle } from 'components/icons/components/outline/IconOutlineInfoCircle';
import { CollectionBreadcrumbs } from 'features/collection/components';
import {
  CollectionFragmentMoveToAnotherCollectionFragment,
  CollectionPermission,
  useGetCollectionForMoveToAnotherCollectionQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { CollectionSearchAutocompleteSection } from './sections/collectionSearchAutocomplete';
import { CollectionMoveToAnotherCollectionDestinationCollectionItemsSection } from './sections/destinationCollectionItems';

type Props = {
  collectionIdToMove?: string;
  currentCollectionId?: string;
  onSelectDestinationCollection: (
    collection: CollectionFragmentMoveToAnotherCollectionFragment,
  ) => void;
  children: React.ReactNode;
  type?: 'post' | 'collection';
};

export const CollectionMoveToAnotherCollectionLayout = ({
  collectionIdToMove,
  currentCollectionId = '',
  onSelectDestinationCollection,
  children,
  type = 'collection',
}: Props) => {
  const [destinationCollectionId, setDestinationCollectionId] =
    useState(currentCollectionId);

  const { data } = useGetCollectionForMoveToAnotherCollectionQuery({
    variables: {
      collectionId: currentCollectionId,
    },
    skip: !currentCollectionId,
  });
  const collectionToMove = data?.collection;

  const {
    data: destinationData,
    loading: destinationCollectionLoading,
    refetch: refetchDestinationCollection,
  } = useGetCollectionForMoveToAnotherCollectionQuery({
    variables: {
      collectionId: destinationCollectionId,
    },
    skip: !destinationCollectionId,
  });
  const destinationCollection = destinationData?.collection;

  useEffect(() => {
    if (destinationCollectionId) {
      refetchDestinationCollection({
        collectionId: destinationCollectionId,
      });
    }
  }, [destinationCollectionId, refetchDestinationCollection]);

  if (currentCollectionId && !collectionToMove) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        sx={{
          width: 550,
          overflow: 'auto',
          bgcolor: '#FAF3ECCC',
          padding: theme.spacing(6, 6, 13, 6),
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          flexGrow={1}
          height="75vh"
          maxHeight={theme.spacing(200)}
        >
          {children}

          <CollectionSearchAutocompleteSection
            collectionIdToMove={collectionIdToMove}
            destinationCollectionId={destinationCollectionId}
            onSelect={(collectionId) => {
              setDestinationCollectionId(collectionId);
            }}
          />

          {destinationCollection ? (
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              alignItems="center"
              sx={{
                color: theme.colors?.primary.black,
              }}
            >
              {destinationCollectionId && (
                <IconButton
                  onClick={() => {
                    setDestinationCollectionId(
                      destinationCollection.parentCollectionId || '',
                    );
                  }}
                  sx={{ color: theme.colors?.primary.black }}
                  disableRipple
                >
                  <IconOutlineArrowLeft size={16} />
                </IconButton>
              )}
              <Typography
                variant="body-lg"
                fontWeight={600}
                color={theme.colors?.utility[700]}
                pb={0.5}
                minWidth={130}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setDestinationCollectionId('');
                }}
              >
                Creative Juicebox &nbsp;&nbsp;/{' '}
              </Typography>
              <CollectionBreadcrumbs
                wrapBreadcrumbs={false}
                collection={destinationCollection}
                delimiter={
                  <Typography variant="body-md" sx={{ pb: 1 }}>
                    /
                  </Typography>
                }
                ellipseAtLength={3}
                sx={{
                  ...theme.typography['body-lg'],
                  alignItems: 'center',
                  color: theme.colors?.utility[800],
                }}
                componentProps={{
                  text: {
                    lastLocationSx: {
                      color: theme.colors?.primary.black,
                    },
                  },
                  breadcrumbContainer: {
                    sx: {
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      flexWrap: 'nowrap',
                    },
                  },
                  breadcrumbText: {
                    sx: {
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    },
                  },
                }}
                onCollectionClick={(collectionId) => {
                  setDestinationCollectionId(collectionId);
                }}
              />
            </Box>
          ) : !destinationCollectionLoading ? (
            <Typography
              variant="body-lg"
              fontWeight={600}
              color={theme.colors?.primary.black}
              pb={1}
              pt={1}
              pl={2}
            >
              Creative Juicebox
            </Typography>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}

          <CollectionMoveToAnotherCollectionDestinationCollectionItemsSection
            collectionIdToMove={collectionIdToMove}
            key={destinationCollectionId}
            collectionId={destinationCollectionId}
            onCollectionClick={(collectionId) => {
              setDestinationCollectionId(collectionId);
            }}
          />
        </Box>

        {destinationCollection?.parentCollectionId &&
          !destinationCollection?.myPermissions.includes(
            CollectionPermission.Update,
          ) && (
            <Box
              position="absolute"
              bottom={theme.spacing(4)}
              sx={{
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                alignItems="center"
                sx={{
                  width: theme.spacing(80),
                  backgroundColor: 'rgba(35, 6, 3, 0.30)',
                  backdropFilter: 'blur(6px)',
                  borderRadius: theme.spacing(6),
                  padding: theme.spacing(1, 2),
                  color: theme.colors?.primary.parchment,
                }}
              >
                <IconOutlineInfoCircle size={16} />
                <Typography
                  variant="body-sm"
                  color={theme.colors?.primary.parchment}
                  fontWeight={500}
                >
                  You only have “View Only Access” for this Collection
                </Typography>
              </Box>
            </Box>
          )}
      </Box>
      <Button
        variant="primary-alt"
        fullWidth
        disabled={
          !destinationCollection?.myPermissions.includes(
            CollectionPermission.Update,
          ) ||
          (type === 'collection' &&
            destinationCollectionId === collectionToMove?.parentCollectionId) ||
          destinationCollectionId === currentCollectionId
        }
        onClick={() => {
          if (destinationCollection) {
            onSelectDestinationCollection(destinationCollection);
          }
        }}
        sx={{
          ...theme.typography['headline-sm'],
          borderRadius: theme.spacing(0, 0, 2, 2),
          padding: theme.spacing(3, 4),
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Box>Move</Box>
      </Button>
    </Box>
  );
};
