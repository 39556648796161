import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Typography, styled } from '@mui/material';
import { DateRangePicker } from 'components/common/DatePicker';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import moment from 'moment';
import { theme } from 'styles/theme';

export const StyledMenuItemContainer = styled(Box)(() => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

export const StyledIconContainer = styled(Box)(() => ({
  backgroundColor: theme.colors?.utility[300],
  borderRadius: theme.spacing(2),
  width: theme.spacing(7),
  height: theme.spacing(7),
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

type FilterByPeriodProps = {
  selectedPeriodLabel: string | null;
  selectedPeriodCustomStartDate: Date | null;
  selectedPeriodCustomEndDate: Date | null;
  onSelectedPeriodLabel: (
    selectedPeriodLabel: string | null,
    startDate: Date | null,
    endDate: Date | null,
  ) => void;
};

export const FilterByPeriod = (props: FilterByPeriodProps) => {
  const {
    selectedPeriodLabel,
    selectedPeriodCustomStartDate,
    selectedPeriodCustomEndDate,
    onSelectedPeriodLabel,
  } = props;

  const {
    isOpen: dateRangeOpen,
    onOpen: onDateRangeOnOpen,
    onClose: onDateRangeOnClose,
  } = useDisclosure();

  return (
    <DropDownMoreMenuItem
      disableRipple
      singleSelectOnly
      label={
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconOutlineCalendar size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">
            Period{' '}
            {selectedPeriodLabel && (
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[600]}
              >
                ({selectedPeriodLabel})
              </Typography>
            )}
          </Typography>
        </StyledMenuItemContainer>
      }
      dropDownOptions={[
        {
          label: <Typography variant="subhead-lg">Today</Typography>,
          isChecked: selectedPeriodLabel === 'Today',
          onClick: () => {
            if (selectedPeriodLabel === 'Today') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'Today',
                moment().toDate(),
                moment().toDate(),
              );
            }
          },
        },
        {
          label: <Typography variant="subhead-lg">Last 7 days</Typography>,
          isChecked: selectedPeriodLabel === 'Last 7 days',
          onClick: () => {
            if (selectedPeriodLabel === 'Last 7 days') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'Last 7 days',
                moment().subtract(7, 'day').toDate(),
                moment().toDate(),
              );
            }
          },
        },
        {
          label: <Typography variant="subhead-lg">Last 30 days</Typography>,
          isChecked: selectedPeriodLabel === 'Last 30 days',
          onClick: () => {
            if (selectedPeriodLabel === 'Last 30 days') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'Last 30 days',
                moment().subtract(30, 'day').toDate(),
                moment().toDate(),
              );
            }
          },
        },
        {
          label: (
            <Typography variant="subhead-lg">
              This year ({moment().startOf('year').year()})
            </Typography>
          ),
          isChecked: selectedPeriodLabel === 'This year',
          onClick: () => {
            if (selectedPeriodLabel === 'This year') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'This year',
                moment().startOf('year').toDate(),
                moment().toDate(),
              );
            }
          },
        },
        {
          label: (
            <Typography variant="subhead-lg">
              Last year ({moment().subtract(1, 'year').year()})
            </Typography>
          ),
          isChecked: selectedPeriodLabel === 'Last year',
          onClick: () => {
            if (selectedPeriodLabel === 'Last year') {
              onSelectedPeriodLabel(null, null, null);
            } else {
              onSelectedPeriodLabel(
                'Last year',
                moment().subtract(1, 'year').startOf('year').toDate(),
                moment().subtract(1, 'year').endOf('year').toDate(),
              );
            }
          },
        },
        {
          onClick: onDateRangeOnOpen,
          label: (
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subhead-lg">
                  {selectedPeriodLabel === 'Custom Range'
                    ? `${moment(selectedPeriodCustomStartDate).format(
                        'DD MMM',
                      )} - 
                              ${moment(selectedPeriodCustomEndDate).format(
                                'DD MMM',
                              )}`
                    : 'Custom Range'}
                </Typography>
                <IconLinearArrowRight
                  size={16}
                  style={{
                    position: 'absolute',
                    right: 16,
                    marginTop: 2,
                  }}
                />
              </Box>
              <DateRangePicker
                value={[
                  moment(selectedPeriodCustomStartDate),
                  moment(selectedPeriodCustomEndDate),
                ]}
                calendars={1}
                open={dateRangeOpen}
                onClose={onDateRangeOnClose}
                onChange={(dates) => {
                  if (dates.length === 2 && dates[0] && dates[1]) {
                    onSelectedPeriodLabel(
                      'Custom Range',
                      dates[0].toDate(),
                      dates[1].toDate(),
                    );
                  }
                }}
                sx={{ '& .MuiTypography-root': { display: 'none' } }}
                slots={{
                  // eslint-disable-next-line
                  textField: () => null,
                }}
                slotProps={{
                  actionBar: {
                    actions: [],
                  },
                }}
              />
            </Box>
          ),
          isChecked: selectedPeriodLabel === 'Custom Range',
        },
      ]}
    />
  );
};
