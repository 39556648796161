import { Box, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { SortOption } from '../types';

type Props = {
  options: SortOption[];
  onOptionClick: (key: string) => void;
};

export const SortByOptions = ({ options, onOptionClick }: Props) => {
  return (
    <>
      {options.map((option) => (
        <Box
          sx={{
            borderRadius: theme.spacing(3),
            padding: theme.spacing(2),
            width: 270,
            justifyContent: 'space-between',
            '&:hover': {
              backgroundColor: theme.colors?.utility[300],
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ width: '100%', cursor: 'pointer' }}
            onClick={() => onOptionClick(option.key)}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(1),
              }}
            >
              {option.icon}
            </Box>
            <Box display="flex" flex={1}>
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                fontWeight={500}
              >
                {option.label}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};
