import { gql } from '@apollo/client';
import {
  BrandFragmentUseSocialListeningOnboardingBrandFragmentDoc,
  CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc,
  TopicFragmentForUseTopicDataForSocialListeningOnboardingFragmentDoc,
  useCreateBrandForUseSocialListeningOnboardingMutation,
  useGetAiGeneratedBrandDescriptionLazyQuery,
  useGetBrandsForUseSocialListeningOnboardingQuery,
  useUpdateBrandForUseSocialListeningOnboardingMutation,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment BrandFragmentUseSocialListeningOnboardingBrand on BrandModel {
    id
    name
    description
    regions
    topics {
      ...TopicFragmentForUseTopicDataForSocialListeningOnboarding
    }
    creators {
      ...CreatorFragmentForUseCreatorDataForSocialListeningOnboarding
    }
  }
  ${CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc}
  ${TopicFragmentForUseTopicDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandsForUseSocialListeningOnboarding {
    brands {
      ...BrandFragmentUseSocialListeningOnboardingBrand
    }
  }
  ${BrandFragmentUseSocialListeningOnboardingBrandFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateBrandForUseSocialListeningOnboarding(
    $data: CreateBrandInput!
  ) {
    createBrand(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateBrandForUseSocialListeningOnboarding(
    $data: UpdateBrandInput!
  ) {
    updateBrand(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetAIGeneratedBrandDescription($brandName: String!) {
    aiGeneratedBrandDescription(brandName: $brandName)
  }
`;

type Props = {
  brandId?: string;
};

export const useBrandDataForSocialListeningOnboarding = ({
  brandId,
}: Props) => {
  const { data: brandsData, refetch: refetchBrand } =
    useGetBrandsForUseSocialListeningOnboardingQuery();
  const [createBrand, { loading: creatingBrand }] =
    useCreateBrandForUseSocialListeningOnboardingMutation();
  const [updateBrand, { loading: updatingBrand }] =
    useUpdateBrandForUseSocialListeningOnboardingMutation();
  const [getAIGeneratedBrandDescriptionQuery] =
    useGetAiGeneratedBrandDescriptionLazyQuery();

  const brand = brandId
    ? brandsData?.brands.find((b) => b.id === brandId)
    : null;

  const handleBrandNameUpdate = async (brandName: string) => {
    let _brandId = '';
    if (brand) {
      await updateBrand({
        variables: {
          data: {
            id: brand.id,
            data: {
              name: brandName,
            },
          },
        },
      });
      _brandId = brand.id;
    } else {
      const brandCreated = await createBrand({
        variables: {
          data: {
            name: brandName,
          },
        },
      });
      _brandId = brandCreated.data?.createBrand.id || '';
    }
    await refetchBrand();
    return _brandId;
  };

  const handleBrandDescriptionUpdate = async (brandDescription: string) => {
    if (brand) {
      await updateBrand({
        variables: {
          data: {
            id: brand.id,
            data: {
              description: brandDescription,
            },
          },
        },
      });
    }
    refetchBrand();
  };

  const handleBrandRegionsUpdate = async (regions: string[]) => {
    if (brand) {
      await updateBrand({
        variables: {
          data: {
            id: brand.id,
            data: {
              regions,
            },
          },
        },
      });
    }
    refetchBrand();
  };

  const getAIGeneratedBrandDescription = async (brandName: string) => {
    return getAIGeneratedBrandDescriptionQuery({
      variables: {
        brandName,
      },
    });
  };

  return {
    brand,
    refetchBrand,

    queries: {
      getAIGeneratedBrandDescription,
    },

    onboardingActions: {
      savingBrandData: creatingBrand || updatingBrand,
      handleBrandNameUpdate,
      handleBrandDescriptionUpdate,
      handleBrandRegionsUpdate,
    },
  };
};
