import { Box, Typography } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { InternalOrganizationRole } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

export const MemberStats = () => {
  const { orgBilling, user } = useUserContext();
  const isSocialListeningEnabled = orgBilling?.socialListeningEnabled;

  const admins = useMemo(
    () =>
      user?.organization.users.filter(
        (u) =>
          u.hasSignedUp &&
          !u.isDisabled &&
          u.hasBeenApprovedByAdmin &&
          u.role === InternalOrganizationRole.Admin,
      ) || [],
    [user],
  );

  const users = useMemo(
    () =>
      user?.organization.users.filter(
        (u) =>
          u.hasSignedUp &&
          !u.isDisabled &&
          u.hasBeenApprovedByAdmin &&
          u.role === InternalOrganizationRole.User,
      ) || [],
    [user],
  );

  const socialListeningUsers = useMemo(
    () =>
      user?.organization.users.filter(
        (u) =>
          u.hasSignedUp &&
          !u.isDisabled &&
          u.hasBeenApprovedByAdmin &&
          u.role === InternalOrganizationRole.SocialListeningUser,
      ) || [],
    [user],
  );

  const memberCount = users.length;
  const memberLimit =
    (orgBilling?.organizationMemberLimit || 1) - admins.length;

  return (
    <Box display="flex" gap={theme.spacing(5)} my={4}>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: theme.spacing(50),
          height: theme.spacing(25),
          backgroundColor: theme.colors?.utility[250],
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pl: 4,
          gap: 2,
        }}
      >
        <Typography
          fontWeight={600}
          variant="body-md"
          color={theme.colors?.utility[700]}
        >
          Admins
        </Typography>
        <Typography variant="headline-lg">{admins.length}</Typography>
      </Box>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: theme.spacing(50),
          height: theme.spacing(25),
          backgroundColor: theme.colors?.utility[250],
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pl: 4,
          gap: 2,
        }}
      >
        <Typography
          fontWeight={600}
          variant="body-md"
          color={theme.colors?.utility[700]}
        >
          All Access Members
        </Typography>
        <Typography variant="headline-lg">
          {memberCount}/{memberLimit}
        </Typography>
      </Box>
      {isSocialListeningEnabled && (
        <Box
          sx={{
            width: 'fit-content',
            minWidth: theme.spacing(50),
            height: theme.spacing(25),
            backgroundColor: theme.colors?.utility[250],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pl: 4,
            gap: 2,
          }}
        >
          <Typography
            fontWeight={600}
            variant="body-md"
            color={theme.colors?.utility[700]}
          >
            Social Listening Members
          </Typography>
          <Typography variant="headline-lg">
            {socialListeningUsers.length}/Unlimited
          </Typography>
        </Box>
      )}
    </Box>
  );
};
