import { gql } from '@apollo/client';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import {
  SOCIAL_LISTENING_SIGNAL_DEFINITION_IDS_FILTER,
  SOCIAL_LISTENING_SIGNAL_OPTION_IDS_FILTER,
} from 'features/socialMediaListening/constants';
import { SignalDefinitionFragmentFilterBySocialSignalDefinitionFragment } from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { useEffect } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SignalDefinitionFragmentFilterBySocialSignalDefinition on SignalDefinitionModel {
    id
    name
    label
    options {
      id
      value
      description
    }
  }
`;

type Props = {
  signalDefinitions: SignalDefinitionFragmentFilterBySocialSignalDefinitionFragment[];
  onValueUpdated: (
    signalDefinitionIds: string[],
    signalDefinitionOptionIds: string[],
  ) => void;
};

export const FilterBySocialSignalDefinition = ({
  signalDefinitions,
  onValueUpdated,
}: Props) => {
  const { value: signalDefinitionIds, setValue: setSignalDefinitionIds } =
    useLocalStorage<string[]>(
      SOCIAL_LISTENING_SIGNAL_DEFINITION_IDS_FILTER,
      [],
    );
  const { value: signalOptionIds, setValue: setSignalOptionIds } =
    useLocalStorage<string[]>(SOCIAL_LISTENING_SIGNAL_OPTION_IDS_FILTER, []);

  const toggleSignalDefinition = (signalDefinitionId: string) => {
    const allSignalOptionsForSignalDefinition = signalDefinitions.find(
      (signalDefinition) => signalDefinition.id === signalDefinitionId,
    )?.options;

    if (!signalDefinitionIds.includes(signalDefinitionId)) {
      const signalDefinitionNewValue = [
        ...signalDefinitionIds,
        signalDefinitionId,
      ];
      setSignalDefinitionIds(signalDefinitionNewValue);

      const signalOptionIdsNewValue = (
        allSignalOptionsForSignalDefinition || []
      ).map((option) => option.id);
      setSignalOptionIds((prev) =>
        Array.from(new Set([...prev, ...signalOptionIdsNewValue])),
      );
      onValueUpdated(signalDefinitionNewValue, signalOptionIdsNewValue);
    } else {
      const signalDefinitionNewValue = signalDefinitionIds.filter(
        (id) => id !== signalDefinitionId,
      );
      setSignalDefinitionIds(signalDefinitionNewValue);

      const signalOptionIdsNewValue = signalOptionIds.filter(
        (id) =>
          !allSignalOptionsForSignalDefinition?.some(
            (option) => option.id === id,
          ),
      );
      setSignalOptionIds(signalOptionIdsNewValue);
      onValueUpdated(signalDefinitionNewValue, signalOptionIdsNewValue);
    }
  };
  const toggleSignalDefinitionOption = (optionId: string) => {
    if (!signalOptionIds.includes(optionId)) {
      const signalOptionsNewValue = [...signalOptionIds, optionId];
      setSignalOptionIds(signalOptionsNewValue);
      onValueUpdated(signalDefinitionIds, signalOptionsNewValue);
    } else {
      const signalOptionsNewValue = signalOptionIds.filter(
        (id) => id !== optionId,
      );
      setSignalOptionIds(signalOptionsNewValue);
      onValueUpdated(signalDefinitionIds, signalOptionsNewValue);
    }
  };

  return (
    <Box sx={{ width: '100%', py: 2 }}>
      {signalDefinitions.map((signalDefinition) => {
        return (
          <Accordion
            sx={{
              '&.MuiAccordion-root': {
                border: 'none',
                boxShadow: 'none',
                borderRadius: theme.spacing(3),
                margin: 0,
                backgroundColor: 'transparent',
                padding: 0,
                '&::before': {
                  display: 'none',
                },
              },
            }}
          >
            <AccordionSummary
              expandIcon={<IconLinearArrowDown size={16} />}
              sx={{
                p: theme.spacing(3, 2),
                m: 0,
                '& .Mui-expanded, &.Mui-expanded, &.MuiAccordionSummary-root': {
                  minHeight: '30px',
                  height: '40px',
                  margin: 0,
                  alignItems: 'center',
                },
              }}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Checkbox
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSignalDefinition(signalDefinition.id);
                  }}
                  disableRipple
                  checked={!!signalDefinitionIds.includes(signalDefinition.id)}
                  sx={{
                    p: 1,
                    color: theme.colors?.primary.black,
                    '&.MuiCheckbox-root': {
                      color: theme.colors?.primary.black,
                    },
                  }}
                />
                <Box display="flex" flexDirection="column" gap={0}>
                  <Typography variant="body-lg">
                    {signalDefinition.label}
                  </Typography>

                  {/* <Typography
                    variant="body-md"
                    color={theme.colors?.utility[600]}
                  >
                    All selected
                  </Typography> */}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  maxHeight: 200,
                  overflowY: 'auto',
                }}
              >
                {signalDefinition.options.map((option) => (
                  <Box
                    key={option.id}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    px={2}
                  >
                    <Checkbox
                      disableRipple
                      checked={!!signalOptionIds.includes(option.id)}
                      sx={{
                        p: 1,
                        color: theme.colors?.primary.black,
                        '&.MuiCheckbox-root': {
                          color: theme.colors?.primary.black,
                        },
                      }}
                      onChange={(e) => {
                        toggleSignalDefinitionOption(option.id);
                      }}
                    />
                    <Typography variant="body-lg">
                      {option.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};
