import { useApolloClient } from '@apollo/client';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldArrowDown } from 'components/icons/components/bold/IconBoldArrowDown';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import {
  ExpandableText,
  useSLBrandPermissions,
} from 'features/socialMediaListening';
import {
  OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment,
  Platform,
  SortOrder,
  useAddLabelsToSocialPostCommentForCommentInsightsTableMutation,
  useRemoveLabelsFromSocialPostCommentForCommentInsightsTableMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { formatBigNumber } from 'utils/number';
import { EditCompetitorLabels } from '../../editCompetitorLabels';
import { CommentInsightsBulkActions } from '../commentInsightsBulkActions';
import { ImageTableCell } from '../commentsInsightsTableCells/imageTableCell';
import { LinkTableCell } from '../commentsInsightsTableCells/linkTableCell';
import { SelectionStateTableCell } from '../commentsInsightsTableCells/selectionStateTableCell';
import { CommentsInsightsTableProps } from './types';

enum SortKeys {
  PostEngagement = 'socialPostEngagementRate',
  CommentEngagement = 'commentEngagement',
}

export const CommentsInsightsTableForCompetitor = ({
  sortData,
  setSortData,
  currentBrandId,
  trackingBrandId,
  comments,
  onLoadMore,
  hasMore,
}: CommentsInsightsTableProps) => {
  const { cache } = useApolloClient();
  const { hasFullAccess } = useSLBrandPermissions({ brandId: currentBrandId });

  const [addLabels] =
    useAddLabelsToSocialPostCommentForCommentInsightsTableMutation();
  const [removeLabels] =
    useRemoveLabelsFromSocialPostCommentForCommentInsightsTableMutation();

  const [selectionState, setSelectionState] = useState<'all' | 'some' | 'none'>(
    'none',
  );

  const [selectedIds, setSelectedIds] = useState([] as string[]);

  useEffect(() => {
    if (selectionState === 'all') {
      setSelectedIds([...comments.map((comment) => comment.id)]);
    }
  }, [selectionState, comments]);

  const handleSelectItem = (id: string) => {
    if (selectionState === 'all') {
      setSelectionState('some');
    }

    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  return (
    <>
      <Box
        sx={{ maxHeight: 500, overflowY: 'auto', width: '100%' }}
        className="comment-insights-container"
      >
        <InfiniteScroll
          loadMore={() => {
            onLoadMore();
          }}
          threshold={50}
          useWindow={false}
          hasMore={hasMore}
        >
          <TableContainer
            className="comment-insights-table-container"
            style={{}}
          >
            <Table className="comment-insights-table" sx={{}}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: theme.spacing(8),
                    }}
                  >
                    {hasFullAccess ? (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {selectionState === 'all' ? (
                          <IconButton
                            disableRipple
                            onClick={() => {
                              setSelectionState('none');
                              setSelectedIds([]);
                            }}
                            sx={{
                              m: 0,
                              p: 0,
                            }}
                          >
                            <IconBoldTickCircle
                              size={24}
                              color={theme.colors?.primary.black}
                            />
                          </IconButton>
                        ) : (
                          <Box
                            onClick={() => {
                              setSelectionState('all');
                              setSelectedIds([
                                ...comments.map((comment) => comment.id),
                              ]);
                            }}
                            sx={{
                              m: 0,
                              //   ml: 2.5,
                              p: 0,
                              cursor: 'pointer',
                              width: theme.spacing(5),
                              height: theme.spacing(5),
                              border: `1px solid ${theme.colors?.utility[800]}`,
                              borderRadius: theme.spacing(5),
                            }}
                          />
                        )}
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...typography['body-lg'],
                      fontWeight: 600,
                      color: theme.colors?.utility[700],
                      width: theme.spacing(12),
                    }}
                  >
                    Post
                  </TableCell>
                  <TableCell
                    sx={{
                      ...typography['body-lg'],
                      fontWeight: 600,
                      color: theme.colors?.utility[700],
                      minWidth: theme.spacing(120),
                      width: theme.spacing(120),
                      maxWidth: theme.spacing(120),
                    }}
                  >
                    Question
                  </TableCell>
                  <TableCell
                    sx={{
                      ...typography['body-lg'],
                      fontWeight: 600,
                      color: theme.colors?.utility[700],
                      minWidth: theme.spacing(16),
                    }}
                  >
                    Link
                  </TableCell>
                  <TableCell
                    sx={{
                      ...typography['body-lg'],
                      fontWeight: 600,
                      color: theme.colors?.utility[700],
                      minWidth: theme.spacing(16),
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      Category
                      <IconCustomSparkles size={16} />
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...typography['body-lg'],
                      fontWeight: 600,
                      color: theme.colors?.utility[700],
                      minWidth: theme.spacing(25),
                    }}
                  >
                    Label
                  </TableCell>
                  <TableCell
                    sx={{
                      ...typography['body-lg'],
                      fontWeight: 600,
                      color: theme.colors?.utility[700],
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (
                        sortData?.key === SortKeys.PostEngagement &&
                        sortData?.order === SortOrder.Desc
                      ) {
                        setSortData?.(undefined);
                      } else {
                        setSortData?.({
                          key: SortKeys.PostEngagement,
                          order:
                            sortData?.key === SortKeys.PostEngagement
                              ? sortData?.order === SortOrder.Asc
                                ? SortOrder.Desc
                                : SortOrder.Asc
                              : SortOrder.Asc,
                        });
                      }
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      sx={{
                        minWidth: theme.spacing(36),
                      }}
                    >
                      Post engagement
                      {sortData?.key === SortKeys.PostEngagement && (
                        <>
                          {sortData?.order === SortOrder.Asc && (
                            <IconBoldArrowDown
                              size={16}
                              style={{
                                transform: 'rotate(180deg)',
                              }}
                            />
                          )}
                          {sortData?.order === SortOrder.Desc && (
                            <IconBoldArrowDown size={16} />
                          )}
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...typography['body-lg'],
                      fontWeight: 600,
                      color: theme.colors?.utility[700],
                      minWidth: theme.spacing(50),
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (
                        sortData?.key === SortKeys.CommentEngagement &&
                        sortData?.order === SortOrder.Desc
                      ) {
                        setSortData?.(undefined);
                      } else {
                        setSortData?.({
                          key: SortKeys.CommentEngagement,
                          order:
                            sortData?.key === SortKeys.CommentEngagement
                              ? sortData?.order === SortOrder.Asc
                                ? SortOrder.Desc
                                : SortOrder.Asc
                              : SortOrder.Asc,
                        });
                      }
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      Comment engagement
                      {sortData?.key === SortKeys.CommentEngagement && (
                        <>
                          {sortData?.order === SortOrder.Asc && (
                            <IconBoldArrowDown
                              size={16}
                              style={{
                                transform: 'rotate(180deg)',
                              }}
                            />
                          )}
                          {sortData?.order === SortOrder.Desc && (
                            <IconBoldArrowDown size={16} />
                          )}
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>

              {comments.length ? (
                <TableBody className="comment-insights-container">
                  {comments.map((comment, index) => {
                    return (
                      <TableRow
                        component={Link}
                        to={PlotRoutes.socialListeningPost(
                          comment.socialPost.id,
                          currentBrandId,
                          { tab: 'comments' },
                        )}
                      >
                        {hasFullAccess ? (
                          <SelectionStateTableCell
                            selected={
                              selectionState === 'all' ||
                              selectedIds.includes(comment.id)
                            }
                            onToggle={() => handleSelectItem(comment.id)}
                          />
                        ) : (
                          <TableCell />
                        )}
                        <ImageTableCell
                          image={comment.socialPost.thumbnailUrl || ''}
                        />
                        <TableCell
                          sx={{
                            ...typography['headline-sm'],
                          }}
                        >
                          {comment.enrichedComment}
                          <Box
                            className="comment-insights-table__comment"
                            mt={1}
                          >
                            <ExpandableText
                              collapsedHeight={24}
                              text={comment.text}
                              componentProps={{
                                text: {
                                  sx: {
                                    color: theme.colors?.utility[800],
                                  },
                                },
                                seeMore: {
                                  label: 'See Original',
                                },
                                seeLess: {
                                  label: 'Hide Original',
                                },
                              }}
                            />
                          </Box>
                        </TableCell>
                        <LinkTableCell
                          platform={Platform.Tiktok}
                          socialPost={comment.socialPost}
                        />
                        <TableCell
                          sx={{
                            ...typography['body-xl'],
                          }}
                        >
                          {comment.categoryName.join(', ') || '-'}
                        </TableCell>
                        <TableCell
                          sx={{ minWidth: 150 }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <EditCompetitorLabels
                            componentProps={{
                              label: {
                                sx: {
                                  fontSize: theme.spacing(3.5),
                                },
                              },
                            }}
                            brandId={trackingBrandId}
                            selectedLabels={
                              comment.organizationSocialPostCommentLabels as unknown as OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment[]
                            }
                            onLabelToggle={(label) => {
                              const labelExistsAlready =
                                comment.organizationSocialPostCommentLabels.find(
                                  (l) => l.id === label.id,
                                );

                              if (labelExistsAlready) {
                                removeLabels({
                                  variables: {
                                    data: {
                                      organizationSocialPostCommentLabelIds: [
                                        label.id,
                                      ],
                                      socialPostCommentIds: [comment.id],
                                    },
                                  },
                                });
                              } else {
                                addLabels({
                                  variables: {
                                    data: {
                                      organizationSocialPostCommentLabelIds: [
                                        label.id,
                                      ],
                                      socialPostCommentIds: [comment.id],
                                    },
                                  },
                                });
                              }

                              modifyObject(
                                cache,
                                comment.id,
                                'SocialPostCommentModel',
                                {
                                  organizationSocialPostCommentLabels: () =>
                                    comment.organizationSocialPostCommentLabels.find(
                                      (l) => l.id === label.id,
                                    )
                                      ? comment.organizationSocialPostCommentLabels.filter(
                                          (l) => l.id !== label.id,
                                        )
                                      : [
                                          ...comment.organizationSocialPostCommentLabels,
                                          label,
                                        ],
                                },
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ ...typography['body-xl'] }}>
                          {(comment.socialPost.engagementRate * 100).toFixed(1)}
                          %
                        </TableCell>
                        <TableCell sx={{ ...typography['body-xl'] }}>
                          {formatBigNumber(comment.commentEngagement)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} sx={{ textAlign: 'center', py: 8 }}>
                      <Typography
                        variant="body-lg"
                        color={theme.colors?.utility[700]}
                      >
                        No comments found
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
      {!!selectedIds.length && (
        <CommentInsightsBulkActions
          brandId={trackingBrandId}
          selectedIds={selectedIds}
          onLabelToggle={(label, type) => {
            if (type === 'add') {
              addLabels({
                variables: {
                  data: {
                    organizationSocialPostCommentLabelIds: [label.id],
                    socialPostCommentIds: selectedIds,
                  },
                },
              });
            } else {
              removeLabels({
                variables: {
                  data: {
                    organizationSocialPostCommentLabelIds: [label.id],
                    socialPostCommentIds: selectedIds,
                  },
                },
              });
            }

            selectedIds.forEach((id) => {
              const commentCache = comments.find((c) => c.id === id);
              modifyObject(cache, id, 'SocialPostCommentModel', {
                organizationSocialPostCommentLabels: () =>
                  type === 'add'
                    ? [
                        ...(commentCache?.organizationSocialPostCommentLabels ||
                          []),
                        label,
                      ]
                    : [
                        ...(
                          commentCache?.organizationSocialPostCommentLabels ||
                          []
                        ).filter((l) => l.id !== label.id),
                      ],
              });
            });
          }}
          onClose={() => {
            setSelectedIds([]);
            setSelectionState('none');
          }}
        />
      )}
    </>
  );
};
