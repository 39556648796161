/**
 * DEPRECATED (?)
 */

import { gql } from '@apollo/client';
import { Masonry } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { POST_FRAGMENT_COLLECTION_POST_DND_POST_CARD } from 'features/collectionPostDnd';
import { PostMasonryCardView } from 'features/post';
import { PostFragmentCollectionCreateFromSmartSearchResultDialogViewFragment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_COLLECTION_CREATE_FROM_SMART_SEARCH_RESULT_DIALOG_VIEW = gql`
  fragment PostFragmentCollectionCreateFromSmartSearchResultDialogView on PostModel {
    id
    ...PostFragmentCollectionPostDndPostCard
  }
  ${POST_FRAGMENT_COLLECTION_POST_DND_POST_CARD}
`;

interface Props {
  onClose: VoidFunction;
  posts: PostFragmentCollectionCreateFromSmartSearchResultDialogViewFragment[];
  name: string;
}

export const CollectionCreateFromSmartSearchResultDialogView = ({
  onClose,
  posts,
  name,
}: Props) => {
  const [selectedPost, setSelectedPost] = useState<
    PostFragmentCollectionCreateFromSmartSearchResultDialogViewFragment[]
  >([]);
  const { triggerCommand } = useCommandContext();

  useEffect(() => {
    setSelectedPost(posts);
  }, [posts]);
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: {
          maxWidth: 1032,
          borderRadius: 6,
        },
      }}
    >
      <DialogTitle sx={{ px: 7, pt: 7 }}>
        <Typography variant="headline-lg">{name}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 8 }}>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
          spacing={4}
          sx={{ justifyContent: 'center' }}
        >
          {posts.map((post) => {
            const selected = selectedPost.includes(post);

            return (
              <Box
                key={post.id}
                sx={{
                  borderRadius: 4,
                  ...(selected && {
                    color: theme.colors?.primary.black,
                    outline: `3px solid ${theme.colors?.primary.black}`,
                    outlineOffset: 3,
                    backgroundColor: theme.colors?.primary.white,
                  }),
                }}
              >
                <PostMasonryCardView
                  post={post}
                  key={post.id}
                  onClick={(e) => {
                    if (selectedPost.includes(post)) {
                      setSelectedPost(
                        selectedPost.filter(
                          (selectedPost) => selectedPost !== post,
                        ),
                      );
                    } else {
                      setSelectedPost([...selectedPost, post]);
                    }
                  }}
                />
              </Box>
            );
          })}
        </Masonry>
      </DialogContent>

      <DialogActions
        sx={{
          padding: theme.spacing(5, 6),
          borderTop: `2px solid ${theme.colors?.utility[275]}`,
        }}
      >
        <Button
          variant="primary-alt"
          sx={{
            ...theme.typography['headline-sm'],
            borderRadius: 2,
          }}
          onClick={() => {
            triggerCommand(COMMAND_TYPE.CREATE_COLLECTION, {
              initialValues: {
                name,
                posts: selectedPost,
              },
            });
            onClose();
          }}
        >
          Create collection
        </Button>
      </DialogActions>
    </Dialog>
  );
};
