import { IconLinearMicrophone } from 'components/icons/components/linear/IconLinearMicrophone';
import { IconLinearNotification1 } from 'components/icons/components/linear/IconLinearNotification1';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { IconOutlineCard } from 'components/icons/components/outline/IconOutlineCard';
import { IconOutlineNotification } from 'components/icons/components/outline/IconOutlineNotification';
import { IconOutlineProfile2user } from 'components/icons/components/outline/IconOutlineProfile2user';
import { IconOutlineShare } from 'components/icons/components/outline/IconOutlineShare';
import { Account } from 'pages/org/settings/account';
import { Billing } from 'pages/org/settings/billing';
import { Members } from 'pages/org/settings/members';
import { Notifications } from 'pages/org/settings/notifications';
import { SocialListening } from 'pages/org/settings/socialListening';
import { SocialsPage } from 'pages/org/settings/socials';

export enum OrganizationTabKey {
  ACCOUNT = 'account',
  MEMBERS = 'members',
  BILLING = 'billing',
  SOCIAL_LISTENING = 'social-listening',
  NOTIFICATIONS = 'notifications',
  SOCIALS = 'socials',
}

export const generateTabsData = (
  options: {
    hideSocialListening?: boolean;
    hideNotifications?: boolean;
    hideSocials?: boolean;
  } = {},
) => [
  {
    name: 'Account Settings',
    key: OrganizationTabKey.ACCOUNT,
    path: 'account',
    icon: <IconLinearUser />,
    component: <Account />,
  },
  {
    name: 'Members',
    key: OrganizationTabKey.MEMBERS,
    path: 'members',
    icon: <IconOutlineProfile2user />,
    component: <Members />,
  },
  {
    name: 'Billing',
    key: OrganizationTabKey.BILLING,
    path: 'billing',
    icon: <IconOutlineCard />,
    component: <Billing />,
  },
  ...(!options.hideSocials
    ? [
        {
          name: 'Socials',
          key: OrganizationTabKey.SOCIALS,
          path: 'socials',
          icon: <IconOutlineShare />,
          component: <SocialsPage />,
        },
      ]
    : []),
  ...(!options.hideSocialListening
    ? [
        {
          name: 'Social Listening',
          key: OrganizationTabKey.SOCIAL_LISTENING,
          path: 'social-listening',
          icon: <IconLinearMicrophone />,
          component: <SocialListening />,
        },
        ...(!options.hideNotifications
          ? [
              {
                name: 'Notifications',
                key: OrganizationTabKey.NOTIFICATIONS,
                path: 'notifications',
                icon: <IconOutlineNotification />,
                component: <Notifications />,
              },
            ]
          : []),
      ]
    : []),
];
