import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Grid, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import { IconOutlineDislike } from 'components/icons/components/outline/IconOutlineDislike';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import {
  SocialListeningContentCalendarSaveButton,
  SocialListeningPostSaveButton,
  useBrandDetails,
  usePostDetailNavigate,
  useSocialListeningPostLinkGenerator,
} from 'features/socialMediaListening';
import { useRenderPostReportedSnackbar } from 'features/socialMediaListening/hooks/useRenderPostReportedSnackbar';
import { SuggestedComment } from 'features/socialMediaListening/views/postDetail/sections/suggestedComment/SuggestedComment';
import { SocialPostBrandEngagementStatus } from 'features/socialPost';
import {
  SocialPostType,
  useGetSocialPostForSocialMediaListeningPostDetailQuery,
} from 'graphql/generated';
import { useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { SocialMediaListeningContentTabs } from '../postSummary/sections/postVideoDialog/sections/SocialMediaListeningContentTabs';
import { SocialListeningReportPostView } from '../reportPost/SocialListeningReportPostView';
import { SocialMediaListeningPostDetailCarousel } from './SocialMediaListeningPostDetailCarousel';
import { SocialMediaListeningPostDetailImage } from './SocialMediaListeningPostDetailImage';
import { SocialMediaListeningPostDetailVideo } from './SocialMediaListeningPostDetailVideo';

const TitleWithInfoTooltip = ({
  title,
  info,
}: {
  title: string;
  info: string;
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Typography
        variant="body-xl"
        color={theme.colors?.utility[700]}
        fontWeight={600}
      >
        {title}
      </Typography>
      <Tooltip title={info}>
        <Box component="span" display="flex" alignItems="center">
          <IconBoldInfoCircleAlt size={16} color={theme.colors?.utility[700]} />
        </Box>
      </Tooltip>
    </Box>
  );
};

type Props = {
  brandId: string;
  socialPostId: string;
  // query params from the listening page to maintain the applied filters and pagination
  searchParams?: URLSearchParams;
  componentProps?: {
    modalView?: boolean;
  };
};

export const SocialMediaListeningPostDetailView = ({
  brandId,
  socialPostId: postId,
  searchParams,
  componentProps,
}: Props) => {
  const { renderLeftArrow, renderRightArrow, topicIds } = usePostDetailNavigate(
    {
      brandId,
      searchParams: searchParams || new URLSearchParams(),
    },
  );
  const {
    isOpen: isReportPostDialogOpen,
    onClose: closeReportPostDialog,
    onOpen: openReportPostDialog,
  } = useDisclosure();
  const { generateLink } = useSocialListeningPostLinkGenerator();

  const { renderToast, showToast } = useRenderPostReportedSnackbar();

  const { brand } = useBrandDetails({ brandId });
  const [renderedHeight, setRenderedHeight] = useState(0);
  const detailsVideoRef = useRef<{
    seekVideoTime: (time: number) => void;
    currentVideoTime: number;
  }>(null);

  const { data: socialPostData, loading } =
    useGetSocialPostForSocialMediaListeningPostDetailQuery({
      variables: {
        socialPostId: postId,
        topicIds: topicIds as string[],
        brandId: brand?.id ?? '',
      },
      skip: !postId || !brand,
    });

  const post = socialPostData?.socialPost;

  if (!post || loading) {
    return <Box mt={16} />;
  }

  const socialPostEngagementSuggestions = (topicIds as string[])?.length
    ? post.socialPostEngagementSuggestionsWithTopic
    : post.socialPostEngagementSuggestions;
  const allTopics = socialPostEngagementSuggestions?.map(
    (suggestion) => suggestion.topic,
  );

  const socialPostEngagementSuggestion = socialPostEngagementSuggestions?.[0];
  const sentiment = socialPostEngagementSuggestion?.sentiment;
  const reason = socialPostEngagementSuggestion?.reason;

  const isModalView = !!componentProps?.modalView;

  return (
    <Box mt={isModalView ? 0 : 16} mx="auto">
      {!componentProps?.modalView && (
        <>
          {renderLeftArrow()}
          {renderRightArrow()}
        </>
      )}
      <Box py={isModalView ? 0 : 16}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={isModalView ? 12 : 8}
            lg={isModalView ? 12 : 7}
            mx="auto"
          >
            <Box display="flex" flexDirection="column" gap={4}>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  gap: 8,
                  backgroundColor: theme.colors?.utility[250],
                  p: theme.spacing(6, 9),
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    flex: 2,
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <TitleWithInfoTooltip
                    title="Topic"
                    info="Topic refers to a specific subject being monitored and analyzed across social media platforms."
                  />
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      gap: 2,
                      mt: 4,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="headline-lg"
                      color={theme.colors?.primary.black}
                    >
                      {allTopics?.[0]?.name ? allTopics[0].name : ''}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: '1px',
                    borderRight: `1px solid ${theme.colors?.utility[300]}`,
                  }}
                />

                <Box
                  sx={{
                    position: 'relative',
                    flex: 5,
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <TitleWithInfoTooltip
                    title="Topic Relevancy"
                    info="Topic relevancy indicates how closely your query matches the topic, helping you decide whether to engage. This ensures high confidence in the content's relevance."
                  />
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      gap: 2,
                      mt: 2,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="headline-lg"
                      color={theme.colors?.primary.black}
                    >
                      {socialPostEngagementSuggestion?.score && (
                        <>
                          {parseFloat(
                            (
                              (socialPostEngagementSuggestion?.score || 0) * 100
                            ).toFixed(1),
                          )}
                          %
                        </>
                      )}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        width: '100%',
                      }}
                    >
                      <Typography
                        color={theme.colors?.utility[700]}
                        variant="body-xl"
                        flex={1}
                      >
                        {reason}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: 'rgba(246, 229, 216, 0.25)',
                  borderRadius: 4,
                  p: 9,
                  display: 'flex',
                  gap: 4,
                  flexDirection: 'column',
                }}
              >
                <Box width="100%" display="flex" justifyContent="space-between">
                  {post.type === SocialPostType.Video ? (
                    <TitleWithInfoTooltip
                      title="Video Analysis"
                      info="Video analysis provides insights into the content, creator, and key highlights relevant to your topic."
                    />
                  ) : (
                    <TitleWithInfoTooltip
                      title="Image Analysis"
                      info="Image analysis provides insights into the content, creator, and key highlights relevant to your topic."
                    />
                  )}
                  <Box display="flex" gap={4} alignItems="center">
                    <Box
                      display="flex"
                      color={theme.colors?.utility['pink-3']}
                      gap={2}
                      alignItems="center"
                      fontWeight={600}
                      sx={{ cursor: 'pointer' }}
                      onClick={openReportPostDialog}
                    >
                      <IconOutlineDislike size={16} />
                      <Typography variant="subhead-lg">Report Post</Typography>
                    </Box>
                    <SocialPostBrandEngagementStatus
                      brandId={brand?.id ?? ''}
                      socialPost={post}
                      showIcon
                      componentProps={{
                        button: {
                          sx: {
                            p: theme.spacing(1, 2),
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" gap={4}>
                  <Box flex={2} sx={{ overflow: 'hidden' }}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      {post.type === SocialPostType.Video && (
                        <SocialMediaListeningPostDetailVideo
                          post={post}
                          onHeightSet={(height) => {
                            setRenderedHeight(height);
                          }}
                          ref={detailsVideoRef}
                        />
                      )}
                      {post.type === SocialPostType.Carousel && (
                        <SocialMediaListeningPostDetailCarousel
                          post={post}
                          onHeightSet={(height) => {
                            setRenderedHeight(height);
                          }}
                        />
                      )}
                      {post.type === SocialPostType.Photo && (
                        <SocialMediaListeningPostDetailImage
                          post={post}
                          onHeightSet={(height) => {
                            setRenderedHeight(height);
                          }}
                        />
                      )}
                      <Box display="flex" gap={4} alignItems="center">
                        <SocialListeningPostSaveButton
                          socialListeningPost={post}
                        />
                        <SocialListeningContentCalendarSaveButton
                          socialPost={post}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    flex={3}
                    height={
                      post.type === SocialPostType.Video
                        ? renderedHeight * 1.1
                        : renderedHeight * 1.8
                    }
                    sx={{ overflow: 'auto' }}
                  >
                    <Box display="flex" flexDirection="column" gap={4}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography variant="headline-lg">
                          {socialPostEngagementSuggestion?.oneLineSummary}
                        </Typography>
                        <Box
                          width="fit-content"
                          component="a"
                          href={generateLink(post)}
                          target="_blank"
                        >
                          <Typography
                            variant="body-xl"
                            fontWeight={500}
                            color={theme.colors?.utility[800]}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            Visit <IconOutlineLink2 size={16} />
                          </Typography>
                        </Box>
                      </Box>
                      <SocialMediaListeningContentTabs
                        brandId={brandId}
                        post={post}
                        currentVideoTimeInSeconds={
                          detailsVideoRef.current?.currentVideoTime || 0
                        }
                        componentProps={{
                          videoHighlights: {
                            onHighlightClicked: (time) => {
                              detailsVideoRef.current?.seekVideoTime?.(time);
                            },
                          },
                          postSummary: {
                            containerSx: {
                              backgroundColor: 'rgba(240, 183, 134, 0.15)',
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              {brandId && <SuggestedComment brandId={brandId} post={post} />}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <SocialListeningReportPostView
        isOpen={isReportPostDialogOpen}
        onClose={() => {
          closeReportPostDialog();
        }}
        handleOnSubmit={() => {
          showToast();
        }}
        postId={post.id}
        brandId={brandId}
      />
      {renderToast()}
    </Box>
  );
};
