import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Popover, SxProps, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearClipboardText } from 'components/icons/components/linear/IconLinearClipboardText';
import { useRef } from 'react';

export type SocialMediaListeningFilterByTopicsButtonProps = {
  topics: {
    id: string;
    name: string;
  }[];
  selectedTopics: string[];
  onChange: (platforms: string[]) => void;
  componentProps?: {
    sx?: SxProps;
  };
};

export const SocialMediaListeningFilterByTopicsButton = (
  props: SocialMediaListeningFilterByTopicsButtonProps,
) => {

  
  const { selectedTopics, onChange, componentProps, topics } = props;

  const anchorElRef = useRef<HTMLButtonElement>(null);

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  const getButtonText = () => {
    const selectedCount = selectedTopics.length;
    if (selectedCount === 0) return 'Topics';
    if (selectedCount === 1) return '1 Topic selected';
    return `${selectedCount} Topics selected`;
  };

  return (
    <>
      <Button
        ref={anchorElRef}
        variant="tertiary"
        startIcon={<IconLinearClipboardText size={16} />}
        endIcon={<IconLinearArrowDown size={16} />}
        sx={{
          borderRadius: 2,
          ...componentProps?.sx,
        }}
        onClick={openPopover}
      >
        <Typography variant="subhead-xl">{getButtonText()}</Typography>
      </Button>
      <Popover
        open={isPopoverOpen}
        onClose={closePopover}
        anchorEl={anchorElRef.current}
        PaperProps={{
          sx: {
            mt: 2,
            p: 4,
            width: 270,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {topics.map(({ id, name }) => {
          const checked = selectedTopics.includes(id);

          return (
            <CheckboxMenuItem
              key={id}
              value={id}
              checked={checked}
              onClick={() => {
                if (!checked) {
                  onChange([...selectedTopics, id]);
                } else {
                  onChange(selectedTopics.filter((x) => x !== id));
                }
              }}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subhead-lg">{name}</Typography>
                </Box>
              }
            />
          );
        })}
      </Popover>
    </>
  );
};
