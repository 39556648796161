export const SOCIAL_LISTENING_POST_VIEW_SETTING =
  'socialListeningPostViewSetting';
export const SOCIAL_LISTENING_TOPIC_RELEVANCY_FILTERS =
  'socialListeningTopicRelevancyFilter';
export const SOCIAL_LISTENING_LIST_FILTERS = 'socialListeningListFilters';
export const SOCIAL_LISTENING_LIST_SORT_BY = 'socialListeningListSortBy';
export const SOCIAL_LISTENING_LIST_SORT_ORDER = 'socialListeningListSortOrder';
export const SOCIAL_LISTENING_TOPIC_IDS_FILTER =
  'socialListeningTopicIdsFilter';
export const SOCIAL_LISTENING_SIGNAL_DEFINITION_IDS_FILTER =
  'socialListeningSignalDefinitionIdsFilter';
export const SOCIAL_LISTENING_SIGNAL_OPTION_IDS_FILTER =
  'socialListeningSignalOptionIdsFilter';
export const SOCIAL_LISTENING_ORGANIC_POSTS_FILTER =
  'socialListeningOrganicPostsFilter';
