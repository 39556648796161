import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { useUserContext } from 'contexts/users/User.context';
import {
  SentimentIcon,
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningPostDetailDialogView,
} from 'features/socialMediaListening';
import { SocialPostClaimsCard } from 'features/socialPost';
import {
  GetCompetitorSentimentInteractionsInput,
  Platform,
  Sentiment,
  SentimentMedium,
  useGetCompetitorSentimentInteractionsForCompetitorSentimentDetailsModalQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

type CompetitorSentimentDetailsModalProps = {
  currentTheme: string;
  sentiment: Sentiment;
  medium: SentimentMedium;
  dateRange: [Date, Date];
  competitorId: string;
  currentBrandId: string;
  trackingBrandId: string;
  currentPlatform: Platform;

  isOpen: boolean;
  onClose: () => void;
};

export const CompetitorSentimentDetailsModal = ({
  currentTheme,
  sentiment,
  dateRange: _dateRange,
  medium: _medium,
  competitorId,
  currentBrandId,
  trackingBrandId,
  currentPlatform,
  isOpen,
  onClose,
}: CompetitorSentimentDetailsModalProps) => {
  const { user } = useUserContext();

  const socialPostDisclosure = useDisclosure();
  const [dateRange, setDateRange] = useState<[Date, Date]>(_dateRange);
  const [medium, setMedium] = useState(_medium);
  const [selectedSocialPostId, setSelectedSocialPostId] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setDateRange(_dateRange);
      setMedium(_medium);
    }
  }, [isOpen]);

  const payload: GetCompetitorSentimentInteractionsInput = {
    competitorId,
    filters: {
      atrributes: [currentTheme],
      sentiment,
      dateRange,
      medium,
      platform: currentPlatform,
    },
  };

  const {
    data: sentimentDetailsData,
    loading,
    fetchMore,
  } = useGetCompetitorSentimentInteractionsForCompetitorSentimentDetailsModalQuery(
    {
      variables: {
        brandId: currentBrandId,
        data: payload,
      },
    },
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: theme.spacing(6),
          minWidth: theme.spacing(200),
          minHeight: theme.spacing(150),
        },
        // opacity: selectedSocialPost ? 0 : 1,
      }}
    >
      <Box
        sx={{
          p: theme.spacing(9, 8),
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="body-xl"
          fontWeight={600}
          color={theme.colors?.utility[800]}
          display="flex"
          alignItems="center"
          gap={1}
        >
          <>
            <SentimentIcon sentiment={sentiment} size={16} />
            <>
              {sentiment === Sentiment.Positive
                ? 'Positive'
                : sentiment === Sentiment.Negative
                ? 'Negative'
                : 'Neutral'}{' '}
              sentiment
            </>
          </>
        </Typography>

        <Typography variant="headline-lg">{currentTheme}</Typography>

        <Box display="flex" alignItems="center" gap={3} mt={4}>
          <ContextMenu
            sx={{
              '& .MuiMenuItem-root': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={[
              {
                renderOption: () => (
                  <Box display="flex" alignItems="center">
                    <Radio checked={medium === SentimentMedium.InPosts} />
                    <Typography variant="body-lg">In Posts</Typography>
                  </Box>
                ),
                onClick: () => {
                  setMedium(SentimentMedium.InPosts);
                },
              },
              {
                renderOption: () => (
                  <Box display="flex" alignItems="center">
                    <Radio checked={medium === SentimentMedium.InComments} />
                    <Typography variant="body-lg">In Comments</Typography>
                  </Box>
                ),
                onClick: () => {
                  setMedium(SentimentMedium.InComments);
                },
              },
            ]}
            renderButton={() => (
              <Button
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  gap: 2,
                  p: theme.spacing(1, 2),
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: theme.colors?.utility[275],
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05);',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <IconLinearSort size={16} />
                </Box>
                <Typography fontWeight={500} variant="body-xl">
                  Medium
                </Typography>
              </Button>
            )}
          />

          <SocialMediaListeningFilterByDateRangeButton
            onChange={(range) => {
              if (range[0] && range[1]) {
                setDateRange(range as [Date, Date]);
              }
            }}
            selectedDateRange={dateRange}
          />
        </Box>

        <Divider
          sx={{
            my: theme.spacing(4),
          }}
        />

        <Typography
          variant="body-xl"
          fontWeight={600}
          color={theme.colors?.utility[700]}
          mb={2}
        >
          {
            sentimentDetailsData?.getCompetitorSentimentInteractions.meta
              .totalCount
          }{' '}
          interactions
        </Typography>

        <Box
          sx={{
            height: theme.spacing(100),
            overflowY: 'auto',
          }}
        >
          <InfiniteScroll
            useWindow={false}
            threshold={40}
            loadMore={async () => {
              if (loading) return;
              fetchMore({
                variables: {
                  data: {
                    ...payload,
                    after:
                      sentimentDetailsData?.getCompetitorSentimentInteractions
                        .pageInfo.endCursor,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  return {
                    ...fetchMoreResult,
                    getCompetitorSentimentInteractions: {
                      ...fetchMoreResult.getCompetitorSentimentInteractions,
                      data: {
                        ...prev.getCompetitorSentimentInteractions.data,
                        socialPosts: [
                          ...(prev?.getCompetitorSentimentInteractions?.data
                            ?.socialPosts || []),
                          ...(fetchMoreResult.getCompetitorSentimentInteractions
                            .data.socialPosts || []),
                        ],
                        socialPostComments: [
                          ...(prev?.getCompetitorSentimentInteractions?.data
                            ?.socialPostComments || []),
                          ...(fetchMoreResult.getCompetitorSentimentInteractions
                            .data.socialPostComments || []),
                        ],
                      },
                      pageInfo: {
                        ...prev.getCompetitorSentimentInteractions.pageInfo,
                        ...fetchMoreResult.getCompetitorSentimentInteractions
                          .pageInfo,
                      },
                    },
                  };
                },
              });
            }}
            hasMore={
              sentimentDetailsData?.getCompetitorSentimentInteractions.pageInfo
                .hasNextPage
            }
          >
            <Grid container spacing={2}>
              {medium === SentimentMedium.InPosts &&
                (
                  sentimentDetailsData?.getCompetitorSentimentInteractions?.data
                    ?.socialPosts || []
                ).map((socialPost) => {
                  const brandClaimsByAttribute = socialPost.brandAnalysis
                    .filter(
                      (x) =>
                        x.brandId === trackingBrandId &&
                        (sentiment === Sentiment.Positive
                          ? x.sentimentScore && x.sentimentScore >= 0
                          : sentiment === Sentiment.Negative
                          ? x.sentimentScore && x.sentimentScore < 0
                          : x.sentimentScore === 0),
                    )
                    .flatMap((x) => x.brandClaims)
                    .filter((claim) => claim.attribute === currentTheme)
                    .map((claim) => claim.claim)
                    .filter(
                      (claim, index, self) => self.indexOf(claim) === index,
                    );

                  return (
                    <Grid
                      key={socialPost.id}
                      item
                      xs={6}
                      sx={{ cursor: 'pointer', mt: 2 }}
                      onClick={() => {
                        setSelectedSocialPostId(socialPost.id);
                        socialPostDisclosure.onOpen();
                      }}
                    >
                      <SocialPostClaimsCard
                        socialPost={socialPost}
                        sentiment={sentiment}
                        claims={brandClaimsByAttribute}
                      />
                    </Grid>
                  );
                })}

              {medium === SentimentMedium.InComments &&
                (
                  sentimentDetailsData?.getCompetitorSentimentInteractions?.data
                    ?.socialPostComments || []
                ).map((socialPostComment) => {
                  const claims = socialPostComment.socialPostCommentClaims
                    .filter(
                      (claim) =>
                        claim.brandId === trackingBrandId &&
                        claim.claimAttribute === currentTheme,
                    )
                    .map((x) => x.claim)
                    .filter(
                      (claim, index, self) => self.indexOf(claim) === index,
                    );

                  return (
                    <Grid
                      key={socialPostComment.id}
                      item
                      xs={6}
                      sx={{ cursor: 'pointer', mt: 2 }}
                      onClick={() => {
                        setSelectedSocialPostId(
                          socialPostComment.socialPost.id,
                        );
                        socialPostDisclosure.onOpen();
                      }}
                    >
                      <SocialPostClaimsCard
                        socialPost={socialPostComment.socialPost}
                        sentiment={sentiment}
                        customLabel={socialPostComment.text}
                        claims={claims}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Box>

      {/* <Box
          width="100%"
          display="flex"
          gap={2}
          alignItems="center"
          sx={{
            borderBottom: `2px solid ${theme.colors?.utility[300]}`,
            pb: 2,
          }}
        >
          <TimeDurationSelector
            options={[
              MetricsTimeRange.OneWeek,
              MetricsTimeRange.OneMonth,
              MetricsTimeRange.ThreeMonths,
              MetricsTimeRange.AllTime,
            ]}
            currentValue={timeRange}
            onChange={(value) => {
              setTimeRange?.(value as MetricsTimeRange);
            }}
          />
        </Box>

        <Box sx={{ height: theme.spacing(100), overflow: 'auto' }}>
          {!socialPosts.length && (
            <Box textAlign="center" mt={6}>
              <Typography variant="body-lg" color={theme.colors?.utility[800]}>
                No data available
              </Typography>
            </Box>
          )}
          <InfiniteScroll
            useWindow={false}
            threshold={40}
            loadMore={async () => {
              if (fetchingSocialPosts) return;

              fetchMore({
                variables: {
                  brandId: brandSentimentTrend.data.brandId,
                  categoryName: brandSentimentTrend.data.name,
                  sentiment: brandSentimentTrend.data.sentiment,
                  topicIds,
                  timeRange,
                  after:
                    socialPostsData?.getSocialPostsByTrend.pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  return {
                    ...fetchMoreResult,
                    getSocialPostsByTrend: {
                      ...fetchMoreResult.getSocialPostsByTrend,
                      data: [
                        ...prev.getSocialPostsByTrend.data,
                        ...fetchMoreResult.getSocialPostsByTrend.data,
                      ],
                      pageInfo: {
                        ...prev.getSocialPostsByTrend.pageInfo,
                        ...fetchMoreResult.getSocialPostsByTrend.pageInfo,
                      },
                    },
                  };
                },
              });
            }}
            hasMore={
              socialPostsData?.getSocialPostsByTrend.pageInfo.hasNextPage
            }
          >
            <Grid container spacing={2}>
              {socialPosts.map((socialPost) => {
                const brandClaimsByAttribute = socialPost.brandAnalysis
                  .filter(
                    (x) =>
                      x.brand.id === brandSentimentTrend.data.brandId &&
                      (brandSentimentTrend.data.sentiment === Sentiment.Positive
                        ? x.sentimentScore && x.sentimentScore >= 0
                        : brandSentimentTrend.data.sentiment ===
                          Sentiment.Negative
                        ? x.sentimentScore && x.sentimentScore < 0
                        : x.sentimentScore === 0),
                  )
                  .flatMap((x) => x.brandClaims)
                  .filter(
                    (claim) =>
                      claim.attribute === brandSentimentTrend.data.name,
                  )
                  .map((claim) => claim.claim)
                  .filter(
                    (claim, index, self) => self.indexOf(claim) === index,
                  );

                return (
                  <Grid
                    key={socialPost.id}
                    item
                    xs={6}
                    sx={{ cursor: 'pointer', mt: 2 }}
                    onClick={() => {
                      setCurrentSocialPostId(socialPost.id || '');
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: theme.colors?.utility[250],
                        borderRadius: 3,
                        p: theme.spacing(3, 4),
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 2,
                      }}
                    >
                      {socialPost.thumbnailUrl ? (
                        <Box
                          component="img"
                          width={theme.spacing(15)}
                          minWidth={theme.spacing(15)}
                          height={theme.spacing(21)}
                          minHeight={theme.spacing(21)}
                          sx={{
                            objectFit: 'cover',
                            borderRadius: theme.spacing(3),
                            backgroundColor: theme.colors?.utility[400],
                          }}
                          src={socialPost.thumbnailUrl}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={theme.spacing(15)}
                          height={theme.spacing(21)}
                          animation={false}
                          sx={{
                            minWidth: theme.spacing(15),
                            borderRadius: theme.spacing(3),
                          }}
                        />
                      )}
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="body-lg"
                          color={theme.colors?.utility[1000]}
                          fontWeight={500}
                        >
                          {
                            socialPost.socialPostEngagementSuggestions?.[0]
                              ?.oneLineSummary
                          }
                        </Typography>
                        <Box
                          display="flex"
                          gap={2}
                          alignItems="center"
                          flexWrap="wrap"
                        >
                          {brandClaimsByAttribute.map((claim) => (
                            <Typography
                              variant="body-lg"
                              sx={{
                                p: theme.spacing(1, 2),
                                borderRadius: theme.spacing(1),
                                ...SentimentColorsSx.Default,
                                ...(brandSentimentTrend.data.sentiment ===
                                  Sentiment.Positive &&
                                  SentimentColorsSx.POSITIVE),
                                ...(brandSentimentTrend.data.sentiment ===
                                  Sentiment.Negative &&
                                  SentimentColorsSx.NEGATIVE),
                              }}
                            >
                              {claim}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Box>
      {selectedSocialPost && (
        <SocialMediaListeningPostDetailDialogView
          title={currentCategory}
          postId={selectedSocialPost.id}
          isOpen={!!currentSocialPostId}
          onClose={() => setCurrentSocialPostId('')}
        />
      )} */}

      <SocialMediaListeningPostDetailDialogView
        {...socialPostDisclosure}
        postId={selectedSocialPostId}
        brandId={currentBrandId}
      />
    </Dialog>
  );
};
