import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  BrandMetricsFilters,
  Platform,
  TopicModel,
  useGetBrandOutboundLikeMetricsForSocialMediaListeningAnalyticsOutboundViewQuery,
  useGetBrandOutboundReplyMetricsForSocialMediaListeningAnalyticsOutboundViewQuery,
  useGetBrandOutboundResponseMetricsForSocialMediaListeningAnalyticsOutboundViewQuery,
  useGetBrandsDataForSocialMediaListeningAnalyticsOutboundViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import {
  BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART,
  LeaderboardSection,
  MetricsChart,
  SocialMediaListeningMostEngagedResponses,
} from './sections';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandsDataForSocialMediaListeningAnalyticsOutboundView {
    brands {
      id
      __typename
      name
      topics {
        id
        name
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetBrandOutboundResponseMetricsForSocialMediaListeningAnalyticsOutboundView(
    $brandId: String!
    $filters: BrandMetricsFilters!
  ) {
    brandOutboundResponseMetrics(filters: $filters, brandId: $brandId) {
      ...BrandMetricsResponseFragmentMetricsChart
    }
  }
  ${BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART}
`;

// eslint-disable-next-line
gql`
  query GetBrandOutboundLikeMetricsForSocialMediaListeningAnalyticsOutboundView(
    $brandId: String!
    $filters: BrandMetricsFilters!
  ) {
    brandOutboundLikeMetrics(filters: $filters, brandId: $brandId) {
      ...BrandMetricsResponseFragmentMetricsChart
    }
  }
  ${BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART}
`;

// eslint-disable-next-line
gql`
  query GetBrandOutboundReplyMetricsForSocialMediaListeningAnalyticsOutboundView(
    $brandId: String!
    $filters: BrandMetricsFilters!
  ) {
    brandOutboundReplyMetrics(filters: $filters, brandId: $brandId) {
      ...BrandMetricsResponseFragmentMetricsChart
    }
  }
  ${BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART}
`;

/**
 * This utility shift the start date to the beginning of the day
 * and the end date to the end of the day.
 */
export const shiftDateRangeForQuery = (dateRange: Date[]) => {
  return [
    moment(dateRange[0]).startOf('day').toDate(),
    moment(dateRange[1]).endOf('day').toDate(),
  ];
};

export const SocialMediaListeningAnalyticsOutboundView = () => {
  const { brandId = '' } = useParams();
  const { data: brandData } =
    useGetBrandsDataForSocialMediaListeningAnalyticsOutboundViewQuery();

  const brand = brandData?.brands.find((b) => b.id === brandId);
  const allTopics: Pick<TopicModel, 'id' | 'name'>[] =
    brandData?.brands.flatMap((brand) => brand?.topics) || [];

  // For brand outbound response metrics
  const [
    brandOutboundResponseMetricsFilters,
    setBrandOutboundResponseMetricsFilters,
  ] = useState<BrandMetricsFilters>({
    // Default all platforms
    platforms: Object.values(Platform),
    // Default 30 days
    dateRange: [moment().subtract(30, 'days').toDate(), moment().toDate()],
    topicIds: [],
  });

  const {
    data: brandOutboundResponseMetricsData,
    refetch: refetchBrandOutboundResponseMetrics,
  } =
    useGetBrandOutboundResponseMetricsForSocialMediaListeningAnalyticsOutboundViewQuery(
      {
        variables: {
          brandId: brand?.id || '',
          filters: {
            ...brandOutboundResponseMetricsFilters,
            dateRange: brandOutboundResponseMetricsFilters.dateRange
              ? shiftDateRangeForQuery(
                  brandOutboundResponseMetricsFilters.dateRange,
                )
              : undefined,
          },
        },
        skip: !brand?.id,
      },
    );

  useEffect(() => {
    if (brand?.id) {
      refetchBrandOutboundResponseMetrics();
    }
  }, [
    refetchBrandOutboundResponseMetrics,
    brandOutboundResponseMetricsFilters.topicIds,
    brand?.id,
  ]);

  const brandOutboundResponseMetrics =
    brandOutboundResponseMetricsData?.brandOutboundResponseMetrics || {
      total: 0,
      chartDataPoints: [],
    };

  // For brand outbound like metrics
  const [brandOutboundLikeMetricsFilters, setBrandOutboundLikeMetricsFilters] =
    useState<BrandMetricsFilters>({
      // Default all platforms
      platforms: Object.values(Platform),
      // Default 30 days
      dateRange: [moment().subtract(30, 'days').toDate(), moment().toDate()],
      topicIds: [],
    });

  const {
    data: brandOutboundLikeMetricsData,
    refetch: refetchBrandOutboundLikeMetrics,
  } =
    useGetBrandOutboundLikeMetricsForSocialMediaListeningAnalyticsOutboundViewQuery(
      {
        variables: {
          brandId: brand?.id || '',
          filters: {
            ...brandOutboundLikeMetricsFilters,
            dateRange: brandOutboundLikeMetricsFilters.dateRange
              ? shiftDateRangeForQuery(
                  brandOutboundLikeMetricsFilters.dateRange,
                )
              : undefined,
          },
        },
        skip: !brand,
      },
    );

  useEffect(() => {
    if (brand?.id) {
      refetchBrandOutboundLikeMetrics();
    }
  }, [
    refetchBrandOutboundLikeMetrics,
    brandOutboundLikeMetricsFilters.topicIds,
    brand?.id,
  ]);

  const brandOutboundLikeMetrics =
    brandOutboundLikeMetricsData?.brandOutboundLikeMetrics || {
      total: 0,
      chartDataPoints: [],
    };

  // For brand outbound reply metrics
  const [
    brandOutboundReplyMetricsFilters,
    setBrandOutboundReplyMetricsFilters,
  ] = useState<BrandMetricsFilters>({
    // Default all platforms
    platforms: Object.values(Platform),
    // Default 30 days
    dateRange: [moment().subtract(30, 'days').toDate(), moment().toDate()],
    topicIds: [],
  });

  const {
    data: brandOutboundReplyMetricsData,
    refetch: refetchBrandOutboundReplyMetrics,
  } =
    useGetBrandOutboundReplyMetricsForSocialMediaListeningAnalyticsOutboundViewQuery(
      {
        variables: {
          brandId: brand?.id || '',
          filters: {
            ...brandOutboundReplyMetricsFilters,
            dateRange: brandOutboundReplyMetricsFilters.dateRange
              ? shiftDateRangeForQuery(
                  brandOutboundReplyMetricsFilters.dateRange,
                )
              : undefined,
          },
        },
        skip: !brand,
      },
    );

  useEffect(() => {
    if (brand?.id) {
      refetchBrandOutboundReplyMetrics();
    }
  }, [
    refetchBrandOutboundReplyMetrics,
    brandOutboundReplyMetricsFilters.topicIds,
    brand?.id,
  ]);

  const brandOutboundReplyMetrics =
    brandOutboundReplyMetricsData?.brandOutboundReplyMetrics || {
      total: 0,
      chartDataPoints: [],
    };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        pt: 9,
        pb: 16,
      }}
    >
      <MetricsChart
        brandId={brandId}
        topics={allTopics}
        metricsData={brandOutboundResponseMetrics}
        labels={{
          title: 'Brand Comments',
          definition: "Total comments you've made on creators' posts.",
          tooltipTitle: 'Interactions',
        }}
        mainColor={theme.colors?.utility[600]}
        highlightColor="rgba(223, 227, 235, 0.50)"
        lastUpdatedAt={moment().subtract(1, 'day').toISOString()}
        filters={brandOutboundResponseMetricsFilters}
        setFilters={setBrandOutboundResponseMetricsFilters}
        emptyText={
          <>
            Oops! No data yet. Time to make some noise! 🎉 <br />
            Start commenting on creators' posts in the Social Listening homepage
            to track your engagement efforts!
          </>
        }
      />
      <MetricsChart
        brandId={brandId}
        topics={allTopics}
        metricsData={brandOutboundLikeMetrics}
        labels={{
          title: 'Total Likes',
          definition: "Total likes received on your brand's comments.",
          tooltipTitle: 'Likes',
        }}
        mainColor={theme.colors?.utility['purple-2']}
        highlightColor="rgba(230, 221, 237, 0.50)"
        lastUpdatedAt={moment().subtract(1, 'day').toISOString()}
        filters={brandOutboundLikeMetricsFilters}
        setFilters={setBrandOutboundLikeMetricsFilters}
        emptyText="Don’t worry, brand love will be coming soon. ❤️"
      />
      <MetricsChart
        brandId={brandId}
        topics={allTopics}
        metricsData={brandOutboundReplyMetrics}
        labels={{
          title: 'Total Replies',
          definition: 'Number of users who replied to your comments.',
          tooltipTitle: 'Responses',
        }}
        mainColor={theme.colors?.utility['teal-2']}
        highlightColor="rgba(205, 226, 223, 0.50)"
        lastUpdatedAt={moment().subtract(1, 'day').toISOString()}
        filters={brandOutboundReplyMetricsFilters}
        setFilters={setBrandOutboundReplyMetricsFilters}
        emptyText="No data. Start commenting to see the magic happen. 🌟"
      />

      {brand && (
        <SocialMediaListeningMostEngagedResponses
          brandId={brand.id}
          topics={allTopics}
        />
      )}

      {brand?.id && (
        <LeaderboardSection brandId={brand?.id} topics={allTopics} />
      )}
    </Box>
  );
};
