import BulletList from '@tiptap/extension-bullet-list';
import Focus from '@tiptap/extension-focus';
import FontFamily from '@tiptap/extension-font-family';
import Highlight from '@tiptap/extension-highlight';
import History from '@tiptap/extension-history';
import Placeholder, { PlaceholderOptions } from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';
import type {
  CommandMenuExtensionOptions,
  EmbedExtensionOptions,
  LinkCustomOptions,
  UseMentionSuggestionProps,
} from 'features/tiptap';
import {
  BlockWrapper,
  Code,
  CommandMenu,
  DisableEnter,
  Drop,
  Dropcursor,
  Embed,
  Emoji,
  FilePaste,
  Link,
  Mention,
  useMentionSuggestion,
} from 'features/tiptap';
import { theme } from 'styles/theme';
import { InputTransformation } from '../extensions/inputTransformation';

export type UseEditorExtensionsProps = {
  starterKit?: Partial<StarterKitOptions>;
  placeholder?: Partial<PlaceholderOptions> | boolean;
  mention?: Partial<UseMentionSuggestionProps> | boolean;
  command?: Partial<CommandMenuExtensionOptions> | boolean;
  disableEnter?: boolean;
  fileDrop?: boolean;
  filePaste?: boolean;
  embed?: Partial<EmbedExtensionOptions> | boolean;
  link?: Partial<LinkCustomOptions>;
  textAlign?: boolean;
};

export const useEditorExtensions = (props: UseEditorExtensionsProps) => {
  const {
    starterKit,
    placeholder,
    mention = true,
    command,
    disableEnter,
    fileDrop = true,
    filePaste = true,
    embed = true,
    link,
    textAlign = true,
  } = props;

  const extensions: any[] = [
    // please see https://tiptap.dev/docs/editor/api/extensions/starter-kit for the detail StartKit extension list
    StarterKit.configure({
      ...starterKit,
      heading:
        starterKit?.heading !== false
          ? {
              levels: [1, 2, 3, 4, 5, 6],
            }
          : false,
      // define bulletList extension below
      bulletList: false,
      // use custom dropcursor below
      dropcursor: false,
      // use custom code mark below
      code: false,
      // use newer version of history below
      history: false,
    }),
    Highlight.configure({
      multicolor: true,
    }),
    Link.configure({
      autolink: false,
      HTMLAttributes: {
        defaultProtocol: 'https',
        class: 'tiptap-link',
      },
      ...link,
    }),
    Underline,
    TextStyle.configure({}),
    FontFamily.configure({
      types: ['textStyle'],
    }),
    Focus.configure({
      className: 'has-focus',
      mode: 'deepest',
    }),
    Emoji,
    Drop.configure({
      disableFileDrop: !fileDrop,
    }),
    Dropcursor.configure({
      color: theme.colors?.utility['blue-3-new'],
      width: 4,
      class: 'drop-cursor',
    }),
    InputTransformation,
    Code,
    BlockWrapper,
    History,
  ];

  if (starterKit?.bulletList !== false) {
    extensions.push(
      BulletList.configure(starterKit?.bulletList).extend({
        addKeyboardShortcuts() {
          return {
            Tab: () =>
              this.editor.isActive('bulletList') ||
              this.editor.isActive('orderedList')
                ? this.editor.commands.sinkListItem('listItem')
                : this.editor.commands.toggleBulletList(),
          };
        },
      }),
    );
  }

  if (placeholder) {
    extensions.push(
      Placeholder.configure({
        emptyEditorClass: 'is-empty',
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return 'Add a heading';
          }

          return typeof placeholder === 'object'
            ? (placeholder.placeholder as string)
            : '';
        },
      }),
    );
  }

  const { suggestion } = useMentionSuggestion(
    typeof mention === 'object' ? mention : {},
  );

  if (mention) {
    extensions.push(
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
      }),
    );
  }

  if (command) {
    extensions.push(
      CommandMenu.configure(typeof command === 'object' ? command : {}),
    );
  }

  if (filePaste) {
    extensions.push(FilePaste);
  }

  if (embed) {
    extensions.push(Embed.configure(typeof embed === 'object' ? embed : {}));
  }

  if (disableEnter) {
    extensions.push(DisableEnter);
  }

  if (textAlign) {
    extensions.push(
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    );
  }

  return {
    extensions,
  };
};

/**
 * Returns the props for the useEditorExtensions hook based on the variant.
 *
 * 1. default: All basic extensions should be enabled
 * 2. inline: Targetting comments & other inline-oriented scenarios
 */
export const getUseEditorExtensionsPropsByVariant = (
  variant: 'default' | 'inline',
  extensions: UseEditorExtensionsProps = {},
): UseEditorExtensionsProps => {
  switch (variant) {
    case 'inline': {
      return {
        starterKit: {
          bulletList: false,
          orderedList: false,
          heading: false,
        },
        command: false,
        disableEnter: true,
        fileDrop: false,
        filePaste: false,
        embed: false,
        link: {
          showTooltip: false,
        },
        textAlign: false,
        ...extensions,
      };
    }
    default: {
      return {};
    }
  }
};
