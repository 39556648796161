import { Box, Checkbox, Typography } from '@mui/material'; // Changed Radio to Checkbox
import { SOCIAL_LISTENING_ORGANIC_POSTS_FILTER } from 'features/socialMediaListening/constants';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  onValueUpdated: (value?: boolean) => void;
};

// These keys are used to match the values in the local storage
enum Keys {
  YES = 'Yes',
  NO = 'No',
  EITHER = 'Either',
}

const Options = {
  ORGANIC: {
    key: Keys.YES,
    label: 'Organic',
    value: true,
  },
  PAID: {
    key: Keys.NO,
    label: 'Paid',
    value: false,
  },
};

export const FilterByOrganicPosts = ({ onValueUpdated }: Props) => {
  const { value: organicPostsFilter, setValue: setOrganicPostsFilter } =
    useLocalStorage<string>(SOCIAL_LISTENING_ORGANIC_POSTS_FILTER, 'Either'); // Default to 'Either'

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (organicPostsFilter === Keys.YES) {
      setSelectedKeys([Keys.YES]);
    } else if (organicPostsFilter === Keys.NO) {
      setSelectedKeys([Keys.NO]);
    } else {
      setSelectedKeys([]);
    }
  }, []);

  const handleCheckboxChange = (key: string) => {
    if (selectedKeys.includes(key)) {
      const newSelectedKeys = selectedKeys.filter((k) => k !== key);
      setSelectedKeys(newSelectedKeys);

      if (newSelectedKeys.length === 1) {
        setOrganicPostsFilter(newSelectedKeys[0]);
        onValueUpdated(newSelectedKeys[0] === Keys.YES);
      } else {
        setOrganicPostsFilter('Either');
        onValueUpdated(undefined);
      }
    } else {
      const newSelectedKeys = [...selectedKeys, key];
      setSelectedKeys(newSelectedKeys);

      if (newSelectedKeys.length === 1) {
        setOrganicPostsFilter(newSelectedKeys[0]);
        onValueUpdated(newSelectedKeys[0] === Keys.YES);
      } else {
        setOrganicPostsFilter('Either');
        onValueUpdated(undefined);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', py: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          disableRipple
          sx={{
            p: 2,
            '&.MuiCheckbox-root': {
              color: theme.colors?.primary.black,
            },
          }}
          checked={selectedKeys.includes(Keys.YES)}
          onChange={(e) => {
            handleCheckboxChange(Keys.YES);
          }}
        />
        <Typography variant="body-lg">{Options.ORGANIC.label}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          disableRipple
          sx={{
            p: 2,
            '&.MuiCheckbox-root': {
              color: theme.colors?.primary.black,
            },
          }}
          checked={selectedKeys.includes(Keys.NO)}
          onChange={(e) => {
            handleCheckboxChange(Keys.NO);
          }}
        />
        <Typography variant="body-lg">{Options.PAID.label}</Typography>
      </Box>
    </Box>
  );
};
