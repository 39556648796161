import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { IconLinearArrowRight3 } from 'components/icons/components/linear/IconLinearArrowRight3';
import { SentimentIcon } from 'features/socialMediaListening/components';
import { SocialMediaListeningPostDetailDialogView } from 'features/socialMediaListening/views/postDetail';
import { SocialPostClaimsCard } from 'features/socialPost';
import {
  MetricsTimeRange,
  Sentiment,
  SocialPostFragmentPostVideoDialogFragment,
  useGetSocialPostsByTrendQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { TimeDurationSelector } from '../../timeDurationSelector/TimeDurationSelector';
import { ThemeDialogProps } from './types';

export const ThemeDialog = ({
  showBackButton,
  isOpen,
  onClose,
  brandSentimentTrend,
  topicIds,
  timeRange: initialTimeRange,
}: ThemeDialogProps) => {
  const { brandId = '' } = useParams<{ brandId: string }>();

  const [currentCategory, setCurrentCategory] = useState('');
  const [currentSocialPostId, setCurrentSocialPostId] = useState('');
  const [selectedSocialPost, setSelectedSocialPost] =
    useState<SocialPostFragmentPostVideoDialogFragment | null>(null);
  const [timeRange, setTimeRange] = useState(initialTimeRange);

  const {
    data: socialPostsData,
    loading: fetchingSocialPosts,
    fetchMore,
    refetch: refetchSocialPostsData,
  } = useGetSocialPostsByTrendQuery({
    variables: {
      brandId: brandSentimentTrend.data.brandId,
      categoryName: brandSentimentTrend.data.name,
      sentiment: brandSentimentTrend.data.sentiment,
      topicIds,
      timeRange,
    },
  });
  const socialPosts = socialPostsData?.getSocialPostsByTrend.data || [];

  useEffect(() => {
    if (currentSocialPostId) {
      const selectedPost = socialPosts.find(
        (socialPost) => socialPost.id === currentSocialPostId,
      );

      if (selectedPost) {
        setSelectedSocialPost(selectedPost);
      }
    }
  }, [currentSocialPostId]);

  useEffect(() => {
    refetchSocialPostsData();
  }, [timeRange]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: theme.spacing(6),
          minWidth: theme.spacing(200),
          minHeight: theme.spacing(150),
        },
        // opacity: selectedSocialPost ? 0 : 1,
      }}
    >
      <Box
        sx={{
          p: theme.spacing(9, 8),
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            display: showBackButton ? 'flex' : 'none',
            width: theme.spacing(6),
            height: theme.spacing(6),
            p: 0,
            transform: 'rotate(180deg)',
          }}
          disableRipple
        >
          <IconLinearArrowRight3 />
        </IconButton>
        <Typography variant="headline-lg" fontWeight={500}>
          {brandSentimentTrend.data.name}
        </Typography>
        <Typography
          variant="body-lg"
          color={theme.colors?.utility[800]}
          display="flex"
          alignItems="center"
          gap={1}
        >
          <>
            <SentimentIcon
              sentiment={brandSentimentTrend?.data.sentiment}
              size={16}
            />
            <>
              {brandSentimentTrend?.data.sentiment === Sentiment.Positive
                ? 'Positive'
                : brandSentimentTrend?.data.sentiment === Sentiment.Negative
                ? 'Negative'
                : 'Neutral'}{' '}
              sentiment
            </>
          </>
        </Typography>

        <Box
          width="100%"
          display="flex"
          gap={2}
          alignItems="center"
          sx={{
            borderBottom: `2px solid ${theme.colors?.utility[300]}`,
            pb: 2,
          }}
        >
          <TimeDurationSelector
            options={[
              MetricsTimeRange.OneWeek,
              MetricsTimeRange.OneMonth,
              MetricsTimeRange.ThreeMonths,
              MetricsTimeRange.AllTime,
            ]}
            currentValue={timeRange}
            onChange={(value) => {
              setTimeRange?.(value as MetricsTimeRange);
            }}
          />
        </Box>

        <Box sx={{ height: theme.spacing(100), overflow: 'auto' }}>
          {!socialPosts.length && (
            <Box textAlign="center" mt={6}>
              <Typography variant="body-lg" color={theme.colors?.utility[800]}>
                No data available
              </Typography>
            </Box>
          )}
          <InfiniteScroll
            useWindow={false}
            threshold={40}
            loadMore={async () => {
              if (fetchingSocialPosts) return;

              fetchMore({
                variables: {
                  brandId: brandSentimentTrend.data.brandId,
                  categoryName: brandSentimentTrend.data.name,
                  sentiment: brandSentimentTrend.data.sentiment,
                  topicIds,
                  timeRange,
                  after:
                    socialPostsData?.getSocialPostsByTrend.pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  return {
                    ...fetchMoreResult,
                    getSocialPostsByTrend: {
                      ...fetchMoreResult.getSocialPostsByTrend,
                      data: [
                        ...prev.getSocialPostsByTrend.data,
                        ...fetchMoreResult.getSocialPostsByTrend.data,
                      ],
                      pageInfo: {
                        ...prev.getSocialPostsByTrend.pageInfo,
                        ...fetchMoreResult.getSocialPostsByTrend.pageInfo,
                      },
                    },
                  };
                },
              });
            }}
            hasMore={
              socialPostsData?.getSocialPostsByTrend.pageInfo.hasNextPage
            }
          >
            <Grid container spacing={2}>
              {socialPosts.map((socialPost) => {
                const brandClaimsByAttribute = socialPost.brandAnalysis
                  .filter(
                    (x) =>
                      x.brand.id === brandSentimentTrend.data.brandId &&
                      (brandSentimentTrend.data.sentiment === Sentiment.Positive
                        ? x.sentimentScore && x.sentimentScore >= 0
                        : brandSentimentTrend.data.sentiment ===
                          Sentiment.Negative
                        ? x.sentimentScore && x.sentimentScore < 0
                        : x.sentimentScore === 0),
                  )
                  .flatMap((x) => x.brandClaims)
                  .filter(
                    (claim) =>
                      claim.attribute === brandSentimentTrend.data.name,
                  )
                  .map((claim) => claim.claim)
                  .filter(
                    (claim, index, self) => self.indexOf(claim) === index,
                  );

                return (
                  <Grid
                    key={socialPost.id}
                    item
                    xs={6}
                    sx={{ cursor: 'pointer', mt: 2 }}
                    onClick={() => {
                      setCurrentSocialPostId(socialPost.id || '');
                    }}
                  >
                    <SocialPostClaimsCard
                      socialPost={socialPost}
                      sentiment={brandSentimentTrend.data.sentiment}
                      claims={brandClaimsByAttribute}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Box>
      {selectedSocialPost && (
        <SocialMediaListeningPostDetailDialogView
          brandId={brandId}
          title={currentCategory}
          postId={selectedSocialPost.id}
          isOpen={!!currentSocialPostId}
          onClose={() => setCurrentSocialPostId('')}
        />
      )}
    </Dialog>
  );
};
