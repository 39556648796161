import { gql } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import { toast } from 'components/common/Toast';
import { IconLinearArrowUp } from 'components/icons/components/linear/IconLinearArrowUp';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import {
  MetricsTimeRange,
  useAddBrandExclusionMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import {
  generateRankingByNumber,
  getDurationNameByMetricsRange,
} from '../../utils';
import { SocialMediaAnalyticsCompetitorsRankingProps } from './types';

const COLORS = [
  theme.colors?.utility['purple-2'],
  theme.colors?.utility['blue-4'],
  theme.colors?.utility['orange-2'],
  theme.colors?.utility['purple-3'],
  theme.colors?.utility['yellow-2-new'],
  theme.colors?.utility['green-2'],
  theme.colors?.utility['teal-2'],
  theme.colors?.utility['pink-2'],
  theme.colors?.utility['blue-3-new'],
  theme.colors?.utility['teal-3'],
  theme.colors?.utility['yellow-3-new'],
  theme.colors?.utility['green-3-new'],
  theme.colors?.utility['orange-3'],
  theme.colors?.utility['pink-3'],
];

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation AddBrandExclusion($data: ExcludeBrandInput!) {
    addBrandExclusion(data: $data) {
      message
      success
    }
  }
`;

export const SocialMediaAnalyticsCompetitorsRanking = ({
  data,
  selectedTimeRange,
  currentBrandId,
  currentBrandName,
  onBrandClicked,
  refreshCompetitorsData,
}: SocialMediaAnalyticsCompetitorsRankingProps) => {
  const pieData = useMemo(
    () =>
      data.data.map((x) => ({
        name: x.brand?.name,
        value: parseFloat(x.percentageShare.toFixed(1)),
      })),
    [data],
  );
  const {
    dialog: confirmExcludeBrandConfirmationDialog,
    onOpen: openExcludeBrandConfirmationDialog,
  } = useConfirmationDialog();
  const [addBrandExclusion] = useAddBrandExclusionMutation();

  const differenceFromLastRank =
    data.currentDurationRank && data.previousDurationRank
      ? data.previousDurationRank - data.currentDurationRank
      : 0;

  const handlePieSectionClick = (brandName) => {
    const clickedBrandId = data.data.find((x) => x.brand?.name === brandName)
      ?.brand?.id;
    if (clickedBrandId) {
      onBrandClicked(clickedBrandId);
    }
  };

  const handleRemoveBrand = async (brandName: string) => {
    const brandToExclude = data.data.find((d) => d.brand?.name === brandName);
    if (brandToExclude) {
      await addBrandExclusion({
        variables: {
          data: {
            id: currentBrandId,
            data: {
              brandIdToExclude: brandToExclude.brand?.id,
            },
          },
        },
      });
      refreshCompetitorsData();
      toast({
        message: `${brandName} has been removed`,
        type: 'success',
      });
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        border: `1px solid ${theme.colors?.utility[300]}`,
        p: 6,
        borderRadius: 6,
      }}
    >
      <Typography
        variant="headline-md"
        fontSize={theme.spacing(5)}
        color={theme.colors?.primary.black}
      >
        Competitors
      </Typography>

      {selectedTimeRange !== MetricsTimeRange.AllTime && (
        <Typography
          variant="headline-md"
          fontSize={theme.spacing(7)}
          color={theme.colors?.utility[700]}
        >
          {data.currentDurationRank ? (
            <>
              {currentBrandName} ranks{' '}
              <Box component="span" color={theme.colors?.utility[800]}>
                {generateRankingByNumber(data.currentDurationRank)}
              </Box>{' '}
              on the list and makes up{' '}
              <Box component="span" color={theme.colors?.utility[800]}>
                {Number(
                  data.data.find((x) => x.brand?.name === currentBrandName)
                    ?.percentageShare || 0,
                ).toFixed(1)}
                %
              </Box>{' '}
              of the share of voice
            </>
          ) : (
            <>
              {currentBrandName} was not mentioned in the{' '}
              {getDurationNameByMetricsRange(selectedTimeRange)}. Keep cranking!
              🫶
            </>
          )}
        </Typography>
      )}

      {differenceFromLastRank !== 0 && (
        <Typography
          display="flex"
          alignItems="center"
          gap={1}
          mt={3}
          variant="body-xl"
          fontWeight={600}
          fontSize={theme.spacing(5)}
          color={
            differenceFromLastRank > 0
              ? theme.colors?.utility['green-3-new']
              : theme.colors?.utility['pink-3']
          }
        >
          <IconLinearArrowUp
            size={16}
            style={{
              transform:
                differenceFromLastRank < 0 ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
          {differenceFromLastRank > 0 ? 'Up' : 'Down'}{' '}
          {Math.abs(differenceFromLastRank)}{' '}
          {Math.abs(differenceFromLastRank) > 1 ? 'places' : 'place'} since{' '}
          {getDurationNameByMetricsRange(selectedTimeRange)}
        </Typography>
      )}

      {pieData.length > 0 ? (
        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                {pieData.map((_data, index) => (
                  <RightClickContextMenuWrapper
                    renderMenu={(props) => (
                      <ContextMenu
                        key={_data.name}
                        renderButton={false}
                        options={[
                          {
                            onClick: () => {
                              openExcludeBrandConfirmationDialog({
                                title: 'Remove brand?',
                                subtitle: `${_data.name} will no longer be tracked as a competitor.`,
                                confirmText: 'Yes, remove brand',
                                onConfirm: () => {
                                  handleRemoveBrand(_data.name);
                                },
                              });
                            },
                            renderOption: () => (
                              <Box display="flex" gap={2} alignItems="center">
                                <Box
                                  display="flex"
                                  width="fit-content"
                                  p={1}
                                  sx={{
                                    borderRadius: theme.spacing(1),
                                    color: theme.colors?.utility['pink-3'],
                                    backgroundColor: 'rgba(35, 6, 3, 0.05)',
                                  }}
                                >
                                  <IconLinearTrash size={16} />
                                </Box>
                                <Typography
                                  variant="body-lg"
                                  color={theme.colors?.utility['pink-3']}
                                  fontWeight={600}
                                >
                                  Remove Brand
                                </Typography>
                              </Box>
                            ),
                          },
                        ]}
                        {...props}
                      />
                    )}
                  >
                    <Box
                      display="flex"
                      gap={2}
                      justifyContent="space-between"
                      sx={{
                        p: 2,
                        borderRadius: theme.spacing(1),
                        '&:hover': {
                          cursor: 'pointer',
                          backgroundColor: theme.colors?.utility[275],
                        },
                      }}
                      onClick={() => {
                        const clickedBrandId = data.data.find(
                          (x) => x.brand?.name === _data.name,
                        )?.brand?.id;
                        if (clickedBrandId) {
                          onBrandClicked(clickedBrandId);
                        }
                      }}
                    >
                      <Typography
                        variant="body-xl"
                        fontWeight={500}
                        color={theme.colors?.utility[900]}
                      >
                        {index + 1}. {_data.name}
                      </Typography>
                      <Typography
                        variant="body-xl"
                        fontWeight={500}
                        color={theme.colors?.utility[700]}
                      >
                        {_data.value}%
                      </Typography>
                    </Box>
                  </RightClickContextMenuWrapper>
                ))}
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{ position: 'relative', height: '100%', width: '100%' }}
            >
              <Typography
                variant="body-xl"
                fontWeight={500}
                color={theme.colors?.primary.black}
                sx={{
                  position: 'absolute',
                  left: 'calc(50% + 4px)',
                  top: 'calc(50% + 8px)',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  fontWeight: 600,
                }}
              >
                Total Volume
                <br />
                <Box component="span" fontSize={theme.spacing(7)}>
                  {formatBigNumber(data.totalVideos || 0)}
                </Box>
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                    onClick={(data) => {
                      handlePieSectionClick(data?.payload.name);
                    }}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    // eslint-disable-next-line react/no-unstable-nested-components
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        return (
                          <Box
                            component="span"
                            display="flex"
                            flexDirection="column" // Set flex direction to column
                            justifyContent="center"
                            alignItems="center"
                            padding="10px"
                            sx={{
                              borderRadius: theme.spacing(2),
                              backgroundColor: theme.colors?.primary.white,
                              border: `1px solid ${theme.colors?.utility[300]}`,
                            }}
                          >
                            <Typography variant="body-xl" textAlign="center">
                              <Box color={theme.colors?.utility[700]}>
                                {payload[0].name}
                              </Box>
                              <Box
                                fontWeight={600}
                                color={theme.colors?.primary.black}
                                fontSize={theme.spacing(5)}
                              >
                                {payload[0].value}%
                              </Box>
                            </Typography>
                          </Box>
                        );
                      }
                      return null;
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box textAlign="center" my={20}>
          <Typography variant="body-lg" color={theme.colors?.utility[700]}>
            No data available
          </Typography>
        </Box>
      )}
      {confirmExcludeBrandConfirmationDialog}
    </Box>
  );
};
