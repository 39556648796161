import { useClipboard, useDisclosure } from '@dwarvesf/react-hooks';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconLinearRedo } from 'components/icons/components/linear/IconLinearRedo';
import { IconOutlineCopy } from 'components/icons/components/outline/IconOutlineCopy';
import {
  useBrandDetails,
  useSocialListeningPostLinkGenerator,
} from 'features/socialMediaListening';
import {
  Platform,
  SocialPostFragmentWhatYouCanSayFragment,
  BrandSocialPostEngagementStatus,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { useBrandSocialPostEngagementMutations } from 'features/socialPost';

type SuggestedCommentProps = {
  brandId: string;
  post: SocialPostFragmentWhatYouCanSayFragment;
};

export const SuggestedComment = ({ brandId, post }: SuggestedCommentProps) => {
  const { brand } = useBrandDetails({ brandId });

  const respondCheckDisclosure = useDisclosure();

  const { generateLink } = useSocialListeningPostLinkGenerator();

  const [currentCommentIndex, setCurrentCommentIndex] = useState(0);
  const allComments = (post.socialPostEngagementSuggestions || [])
    .map((x) => x.suggestedComment)
    .filter((x) => !!x.trim());
  const [currentCommentText, setCurrentCommentText] = useState(
    allComments?.[0] || '',
  );
  const { onCopy } = useClipboard(currentCommentText);

  const analytics = useAnalytics();

  const { updateBrandSocialPostEngagementStatus } =
    useBrandSocialPostEngagementMutations();

  useEffect(() => {
    setCurrentCommentText(allComments[currentCommentIndex]);
  }, [currentCommentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const creator = useMemo(() => {
    if (!brand) {
      return null;
    }

    if (post.platform === Platform.Tiktok) {
      return brand.creators.find((c) => c.platform === Platform.Tiktok);
    }

    if (post.platform === Platform.Instagram) {
      return brand.creators.find((c) => c.platform === Platform.Instagram);
    }

    return null;
  }, [brand, post]);

  if (!post.socialPostEngagementSuggestions?.[0]?.suggestedComment) {
    return null;
  }

  return (
    <Box
      sx={{
        p: theme.spacing(7),
        borderRadius: 4,
        backgroundColor: 'rgba(217, 227, 243, 0.50)',
        display: 'flex',
        gap: 4,
        position: 'relative',
      }}
    >
      <Box display="flex" flexDirection="column" gap={2} flex={3}>
        <Typography
          variant="body-xl"
          color={theme.colors?.utility[700]}
          fontWeight={600}
        >
          Engagement
        </Typography>
        <Typography
          variant="headline-lg"
          display="flex"
          gap={2}
          alignItems="center"
        >
          <IconCustomSparkles size={24} />
          AI Response
        </Typography>
      </Box>
      <Box sx={{ borderRight: `1px solid rgba(35, 6, 3, 0.10)`, mx: 4 }} />
      <Box display="flex" flexDirection="column" gap={2} flex={7}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flex={4}
        >
          <Typography
            variant="body-xl"
            color={theme.colors?.utility[700]}
            fontWeight={600}
            pb={1}
          >
            Draft your comment
          </Typography>
          {allComments.length > 1 && (
            <Box display="flex" gap={1}>
              <IconButton
                disableRipple
                sx={{ p: 0 }}
                disabled={currentCommentIndex === 0}
                onClick={() =>
                  setCurrentCommentIndex((prev) =>
                    prev - 1 > 0 ? prev - 1 : 0,
                  )
                }
              >
                <Box sx={{ display: 'flex', p: 1, transform: 'scale(-1, 1)' }}>
                  <IconLinearRedo />
                </Box>
              </IconButton>
              <IconButton
                disableRipple
                sx={{ p: 0 }}
                disabled={currentCommentIndex === allComments.length - 1}
                onClick={() => setCurrentCommentIndex((prev) => prev + 1)}
              >
                <Box sx={{ display: 'flex', p: 1 }}>
                  <IconLinearRedo />
                </Box>
              </IconButton>
            </Box>
          )}
        </Box>

        <Box
          display="flex"
          gap={4}
          alignItems="flex-start"
          sx={{
            p: 4,
            borderRadius: theme.spacing(4),
            border: '1px solid rgba(35, 6, 3, 0.10)',
            background: 'rgba(255, 255, 255, 0.50)',
          }}
        >
          <Avatar src={creator?.profilePictureUrl || ''} sx={{ w: 8, h: 8 }} />
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            mt={0}
            width="100%"
          >
            <TextField
              multiline
              maxRows={8}
              value={currentCommentText}
              onChange={(e) => setCurrentCommentText(e.target.value)}
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  fontSize: theme.spacing(4),
                },
                '& .MuiInputBase-root': {
                  fontSize: theme.spacing(4),
                  width: '100%',
                  p: 0,
                  backgroundColor: 'transparent',
                  borderRadius: 4,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                onClick={() => {
                  onCopy();
                  window.open(generateLink(post) || '', '_blank');

                  analytics.track(EventName.SocialListeningPostCommentPosted, {
                    postId: post.id,
                  });

                  setTimeout(() => {
                    respondCheckDisclosure.onOpen();
                  }, 1000);
                }}
                variant="primary-alt"
                sx={{
                  '&, &:hover': {
                    borderRadius: 2,
                    border: 'none',
                  },
                }}
                startIcon={<IconOutlineCopy size={16} />}
              >
                Copy and go to post
              </Button>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={respondCheckDisclosure.isOpen}
          onClose={respondCheckDisclosure.onClose}
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: 6,
              backgroundColor: theme.colors?.primary.parchment,
              boxShadow: 'rgba(250, 243, 236, 0.80)',
              backdropFilter: 'blur(20px)',
            },
          }}
        >
          <Box p={6} display="flex" flexDirection="column" gap={4}>
            <Typography variant="headline-lg" fontSize={theme.spacing(7)}>
              Did you respond to this post?
            </Typography>
            <Typography variant="body-xl" fontSize={theme.spacing(5)}>
              Make sure you respond to this post on the social media platform.
            </Typography>

            <Box display="flex" justifyContent="space-between" mt={4} gap={4}>
              <Button
                onClick={async () => {
                  if (brand) {
                    await updateBrandSocialPostEngagementStatus(
                      brand.id,
                      post.id,
                      BrandSocialPostEngagementStatus.Responded,
                    );
                    respondCheckDisclosure.onClose();
                  }
                }}
                variant="primary"
                fullWidth
                sx={{
                  '&, &:hover': {
                    borderRadius: 2,
                    border: 'none',
                    backgroundColor: theme.colors?.utility['green-3-new'],
                    color: theme.colors?.primary.parchment,
                  },
                }}
              >
                Responded
              </Button>
              <Button
                onClick={async () => {
                  if (brand) {
                    await updateBrandSocialPostEngagementStatus(
                      brand.id,
                      post.id,
                      BrandSocialPostEngagementStatus.Pass,
                    );
                    respondCheckDisclosure.onClose();
                  }
                }}
                variant="primary"
                fullWidth
                sx={{
                  '&, &:hover': {
                    borderRadius: 2,
                    border: 'none',
                    backgroundColor: theme.colors?.utility['pink-3'],
                    color: theme.colors?.primary.parchment,
                  },
                }}
              >
                Passed
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};
